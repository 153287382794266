@import 'variables';

/* spacing */

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.width-10 {
  width: 10px !important;
}

/* alignment*/
.text-center {
  text-align: center;
}

.vertical-middle {
  vertical-align: middle;
}

.uppercase {
  text-transform: uppercase;
}

.width-100 {
  width: 100%;
}

.float-right {
  float: right;
  text-align: right;
}

/* colors */

.black {
  color: $black !important;
}

.green {
  color: $green;
}

.red {
  color: $red;
}
