@import 'mixins';

// flex
.flex {
  display: flex;
  box-sizing: border-box;
}

.flex-33-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
}

@for $i from 1 through 100 {
  .flex-#{$i} {
    flex: 1 1 #{$i + '%'};
    max-width: #{$i + '%'};
    box-sizing: border-box;
  }
}

@each $bp, $breakpoint in $breakpoints {
  @include respond-to($bp) {
    @for $i from 1 through 100 {
      .flex-#{$bp}-#{$i} {
        flex: 1 1 100%;
        max-width: #{$i + '%'};
        box-sizing: border-box;
      }
    }
    .flex-#{$bp}-33-33 {
      flex: 1 1 33.33%;
      max-width: 33.33%;
    }
  }
}

// flex direction
$flex-direction: (
  'row': row,
  'row-reverse': row-reverse,
  'col': column,
  'col-reverse': column-reverse,
);

@each $key, $value in $flex-direction {
  .flex-#{$key} {
    flex-direction: $value;
  }
}

@each $bp, $breakpoint in $breakpoints {
  @each $key, $value in $flex-direction {
    @include respond-to($bp) {
      .flex-#{$bp}-#{$key} {
        flex-direction: $value;
      }
    }
  }
}

.flex-fill {
  margin: 0;
  width: 100%;
  height: 100%;
  flex: 1 1 0%;
}

.flex-wrap {
  flex-flow: wrap;
}

@each $bp, $breakpoint in $breakpoints {
  @include respond-to($bp) {
    .flex-#{$bp}-wrap {
      flex-flow: wrap;
    }
  }
}

// flex layout align
$justify-content: (
  'normal': normal,
  'start': flex-start,
  'end': flex-end,
  'center': center,
  'between': space-between,
  'around': space-around,
  'evenly': space-evenly,
  'stretch': stretch,
);

@each $key, $value in $justify-content {
  .justify-#{$key} {
    justify-content: $value;
  }
}

@each $bp, $breakpoint in $breakpoints {
  @each $key, $value in $justify-content {
    @include respond-to($bp) {
      .justify-#{$bp}-#{$key} {
        justify-content: $value;
      }
    }
  }
}

$align-items: (
  'start': flex-start,
  'end': flex-end,
  'center': center,
  'baseline': baseline,
  'stretch': stretch,
);

@each $key, $value in $align-items {
  .align-#{$key} {
    align-items: $value;
    align-content: $value;
    align-self: $value;
  }
}

@each $bp, $breakpoint in $breakpoints {
  @each $key, $value in $align-items {
    @include respond-to($bp) {
      .align-#{$bp}-#{$key} {
        align-items: $value;
        align-content: $value;
        align-self: $value;
      }
    }
  }
}

.align-stretch-row {
  max-height: 100%;
}

.align-stretch-col {
  max-width: 100%;
}

.align-baseline {
  align-items: baseline;
  align-content: stretch;
}

// layout gap
.gap-half-rem {
  gap: 0.5rem;
}

.gap-1rem {
  gap: 1rem;
}

.gap-1-5rem {
  gap: 1.5rem;
}

.gap-2-per {
  gap: 2%;
}

.gap-16px {
  gap: 16px;
}

@for $i from 1 through 40 {
  .gap-#{$i} {
    gap: #{$i}px;
  }
}

@for $i from 1 through 40 {
  .grid-gap-#{$i} {
    margin: 0 -#{$i}px -#{$i}px 0 !important;
    > * {
      padding: 0 #{$i}px #{$i}px 0;
    }
  }
}

@each $bp, $breakpoint in $breakpoints {
  @for $i from 1 through 40 {
    @include respond-to($bp) {
      .grid-gap-#{$bp}-#{$i} {
        margin: 0 -#{$i}px -#{$i}px 0 !important;
        > * {
          padding: 0 #{$i}px #{$i}px 0;
        }
      }
    }
  }
}

.grid-gap-1rem {
  margin: 0px -1rem -1rem 0px !important;
  > * {
    padding: 0px 1rem 1rem 0px;
  }
}

@each $bp, $breakpoint in $breakpoints {
  @include respond-to($bp) {
    .grid-gap-#{$bp}-1rem {
      margin: 0 -1rem -1rem 0 !important;
      > * {
        padding: 0 1rem 1rem 0 !important;
      }
    }
  }
}

@for $i from 1 through 40 {
  .row-gap-#{$i} > *:not(:last-child) {
    margin-right: #{$i}px;
    margin-bottom: 0;
  }
  .col-gap-#{$i} > * {
    margin-bottom: #{$i}px;
    margin-right: 0;
  }
}

@each $bp, $breakpoint in $breakpoints {
  @include respond-to($bp) {
    @for $i from 1 through 40 {
      .row-gap-#{$bp}-#{$i} > *:not(:last-child) {
        margin-right: #{$i}px;
        margin-bottom: 0;
      }
      .col-gap-#{$bp}-#{$i} > * {
        margin-bottom: #{$i}px;
        margin-right: 0;
      }
    }
  }
}

// hide
.hide {
  display: none !important;
}

.show {
  display: block !important;
}

@each $bp, $breakpoint in $breakpoints {
  @include respond-to(#{$bp}) {
    .hide-#{$bp} {
      display: none !important;
      margin: 0 !important;
    }
  }
}

@each $bp, $breakpoint in $breakpoints {
  @include respond-to(#{$bp}) {
    .show-#{$bp} {
      display: block !important;
    }
  }
}
