@import 'mixins';

mat-radio-group > mat-radio-button {
  display: block !important;
  @include respond-to('gt-sm') {
    display: inline-block !important;
  }
}

mat-radio-button + mat-radio-button {
  margin-top: 6px;
  @include respond-to('gt-sm') {
    margin-top: 0;
    margin-left: 6px;
  }
}

mat-radio-group + mat-error {
  font-size: 75%;
}

mat-checkbox + mat-hint {
  display: block;
}

.static-form-field {
  color: black;
}

form div.flex + div.flex {
  margin-top: 15px;
}

.material-label {
  font-size: 0.95em;
  color: rgba(0, 0, 0, 0.6);
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

button.spinner:before {
  content: '';
  /*
  need display != none to override material styling
  as they set display = none inside of
  .mat-focus-indicator::before, _private.scss:16
  */
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
// mat-cards within a form, but not within a flex layout
:not(div.flex) > .form-section + .form-section {
  margin-top: 25px;
}

.form-section {
  mat-card-header {
    margin: -16px -16px 16px !important;
    border-bottom: 1px solid #cdd6da;
    padding: 16px 16px 10px !important;
  }

  .mat-mdc-card-header-text {
    margin: 0 !important;
  }

  .mat-mdc-card-title {
    font-weight: 700;
    margin: 0 0 10px;
  }
}

.content-editor-form-field {
  //margin: 0.25em 0;
  //padding-bottom: 1.34375em;
}

/* The white-on-white of mat-card with a table is too much,
  so we remove some mat-card styles while keeping the connected header
*/
.form-section.with-table {
  background: none;
  box-shadow: none;
  padding: 0;

  mat-card-header {
    border-bottom: none;
    margin-bottom: 0 !important;
    align-items: flex-end;

    .mat-mdc-raised-button {
      margin-bottom: 0;
    }
  }
}

.select-default-option {
  opacity: 0.6;
}

// end mat-cards within a form

/* Remove arrows from number input types */
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox
input[type='number'] {
  -moz-appearance: textfield;
}

.mat-prefix-spinner {
  float: left;
  margin-right: 8px;
  line-height: 27px;
}
