@import 'variables';

$breakpoints: (
  'xs': (
    max-width: 599px,
  ),
  'sm': (
    min-width: 600px,
  ),
  'md': (
    min-width: 960px,
  ),
  'lg': (
    min-width: 1280px,
  ),
  'xl': (
    min-width: 1920px,
  ),
  'lt-sm': (
    max-width: 599px,
  ),
  'lt-md': (
    max-width: 959px,
  ),
  'lt-lg': (
    max-width: 1279px,
  ),
  'lt-xl': (
    max-width: 1919px,
  ),
  'gt-xs': (
    min-width: 600px,
  ),
  'gt-sm': (
    min-width: 960px,
  ),
  'gt-md': (
    min-width: 1280px,
  ),
  'gt-lg': (
    min-width: 1920px,
  ),
  'small': (
    min-width: 767px,
  ),
  'medium': (
    min-width: 992px,
  ),
  'large': (
    min-width: 1200px,
  ),
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
