@import 'variables';

body {
  font-family: $font-default;
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  color: $gray-dk;
}

a {
  color: $blue-dk;
  font-weight: 700;
  text-underline-offset: 4px;
  &:hover {
    color: $gold;
  }
}

a.mat-mdc-raised-button {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
}

h1 {
  font-size: 2.5em;
  line-height: 48px;
  font-weight: 900;
  margin-bottom: 8px;
  color: $blue-dk;
}

/*** PAGE TITLE ***/
/* Content within the page title component is being placed by 
content-projection. For styles to work in projected content, they
must be defined globally instead of within a component.
*/
.page-title h1,
.simple-page-title h1 {
  color: white;
}

/*** END PAGE TITLE ***/

h2,
.subheading-2 {
  font-size: 1.5em;
  line-height: 32px;
  font-weight: 900;
  margin-bottom: 8px;
}

h3,
.subheading-3 {
  font-size: 1.25em;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-block-start: 0px;
}

.alt-search-h1 {
  font-size: 2.5em;
  line-height: 48px;
  font-weight: 900;
  margin-top: 32px;
  margin-bottom: 8px;
  color: $white;
  text-align: center;
}

.alt {
  color: $white !important;
}

:root {
  --mdc-outlined-text-field-outline-color: rgb(224, 224, 224);
  --mdc-outlined-text-field-label-text-size: 14px;
  --mat-sidenav-container-background-color: #194867;
  --mat-form-field-subscript-text-size: 10.5px;
  --mat-form-field-container-text-size: 14px;
  --mat-menu-item-label-text-size: 14px;
  --mat-paginator-container-background-color: transparent;
  --mat-select-trigger-text-size: 14px;
  --mat-form-field-subscript-text-line-height: 15px;
  --mdc-text-button-label-text-tracking: 0.08px;
  --mdc-protected-button-label-text-transform: uppercase;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-typography-body1-font-size: 14px;
  --mdc-typography-caption-font-size: 75%;
  --mdc-typography-caption-line-height: 15px;
}
