@import 'variables';
@import 'mixins';

html {
  scroll-behavior: smooth;
}

div.splash-screen {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: $blue-dk;
  margin: 0;
  padding: 0;

  h1 {
    text-transform: uppercase;
    color: white;
    font-weight: 400;
  }
}

main {
  margin: 0px auto;
  padding: 0 2em;
  &:focus {
    outline: none;
  }
}

.full-height {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100px;
}

.full-width {
  width: 100%;
}

.managed-content a {
  text-decoration: none;
  color: $blue-link;
  padding: 2px;

  &:hover {
    color: $blue-link;
    background-color: $gold;
  }
}

/*** PANELS ***/
.panel {
  margin: 0 -32px;
  padding: 32px;
  min-height: 500px;

  p {
    font-size: 1.2em;
    line-height: 1.5em;
  }
}

.panel-end {
  margin-bottom: -16px;
  margin-top: 16px;
}

.panel-gray {
  background-color: whitesmoke;
}

.panel-header-label {
  color: $gold;
  font-size: 20px;
}

.panel-header-label-alt {
  color: $blue-dk;
  font-size: 20px;
}

.blue-background {
  background: $blue;
  position: relative;
  background-image: url('^assets/images/blue-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.plain-blue-background {
  background: $blue;
  position: relative;
  background-size: cover;
  color: white;
}

.panel-header {
  font-size: 2rem;
}

.panel-header-label + .panel-header,
.panel-header-label-alt + .panel-header {
  margin-top: 6px;
}

.highlight {
  color: $gold;
}

.panel mat-card {
  mat-list-item {
    p {
      margin: 0;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }

    @include respond-to('lt-md') {
      // override height to allow all content to be shown
      height: initial !important;
      [matlistitemline] {
        overflow-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }

  mat-list {
    mat-list-item + mat-list-item {
      @include respond-to('lt-md') {
        // space out items since all content is shown
        margin-top: 15px;
      }
    }
  }
}

.circle-image {
  display: inline-block;
  position: relative;
  width: 350px;
  height: 350px;
  overflow: hidden;
  border-radius: 50%;
  border: solid 5px $white;
}

/*** END PANELS ***/

/* view encapsulation on dynamic html doesn't work in component,
so putting it in global for now
*/
.masthead h1 {
  color: $white;
}

.masthead p {
  font-weight: 400;
  color: $white;
}

.top-header-menu a:hover {
  color: $blue-dk;
}

/*end view encapsulation fix */

/*** SIDENAV ***/
.mat-drawer {
  background-color: $blue-dk;
}

// there's a bug in material that won't let you style the inner container
// without a wrapping element.
// https://github.com/angular/components/issues/13983
.sidenav-custom {
  overflow: hidden !important;
  height: 100%;
}

mat-sidenav {
  overflow: hidden;
}

/*** END SIDENAV ***/

/*** BUTTONS ***/

.mat-mdc-raised-button {
  text-transform: uppercase;
  margin-right: 8px;
  margin-bottom: 8px;
  text-wrap: nowrap;
}

/*** SEARCH ***/
.filter-bar {
  background-color: $gray-lt;
  margin: 0px -32px 16px -32px !important;
  border-radius: 0px !important;
}

.filter-icon {
  font-weight: 700 !important;
  color: $black !important;
}

.filter-header {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
}

.filter mat-icon {
  margin-right: 8px;
}

mat-panel-title {
  margin-top: 0px;
  line-height: 1.5 em;
}

mat-panel-description .searchtitle {
  font-size: 1em;
  font-weight: 400;
  margin: 0;
  color: $black;
  line-height: 1.5 em;
  display: block;
}

.mat-accordion.filter > .mat-expansion-panel-spacing:last-child {
  margin-bottom: 16px;
}

.global-search-form-fields {
  background-color: $white;
  margin: 24px 0;

  @include respond-to('gt-sm') {
    width: 900px;
  }
}

/*** END SEARCH ***/

/*** LISTS ***/
.mat-mdc-paginator {
  background: none;

  .mat-mdc-paginator-container {
    @include respond-to('lt-sm') {
      justify-content: flex-start;
    }
  }

  .mat-mdc-paginator-range-label,
  .mat-mdc-paginator-page-size-label {
    @include respond-to('lt-sm') {
      margin: 0;
    }
  }
}

/*** END LISTS ***/

.wrap {
  overflow-wrap: break-word;
  word-break: break-word;
}

div[flexLayout] + div[flexLayout] {
  margin-top: 20px;
}

.action-buttons {
  margin-top: 20px;

  mat-button + mat-button,
  .mat-mdc-raised-button + .mat-mdc-raised-button {
    margin-left: 10px;
  }
}

.mat-mdc-raised-button + .mat-mdc-raised-button {
  margin-left: 10px;
}

.wizard-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.wizard-buttons button + button {
  margin-left: 10px;
}

table[mat-table] {
  width: 100%;
}

.mat-mdc-snack-bar-container {
  &.snackbar-success {
    --mdc-snackbar-container-color: rgb(0, 128, 0);
    --mat-mdc-snack-bar-button-color: rgb(255, 255, 255);
    --mdc-snackbar-supporting-text-color: rgb(255, 255, 255);
  }

  &.snackbar-failure {
    --mdc-snackbar-container-color: rgb(193, 32, 38);
    --mat-mdc-snack-bar-button-color: rgb(255, 255, 255);
    --mdc-snackbar-supporting-text-color: rgb(255, 255, 255);
  }
}

.mat-mdc-tab-label-container .mat-mdc-tab-list {
  flex-grow: unset;
  & .mat-mdc-tab.mdc-tab {
    min-width: 160px;
  }
}

.link-list mat-icon {
  color: $blue-dk !important;
  font-weight: bold;
}

.external-link-icon {
  transform: rotate(-45deg);
}

.link {
  color: $blue;
  text-decoration: underline;
}

.alert.alert-warn {
  a {
    font-weight: 700;
  }

  mat-list-item {
    height: auto !important;
    padding: 0 !important;

    mat-list-icon {
      padding-left: 0;
    }
  }

  mat-list-item p {
    margin: 5px 0;
  }
}

/*** Partner Card ***/
.partner-card {
  min-height: 305px;

  &:hover {
    cursor: pointer;
  }

  .image-container {
    height: 200px;
    margin-bottom: 16px;
    img {
      max-width: 175px;
      max-height: 200px;
      display: block;
      margin: auto;
    }
  }
  mat-card-footer {
    padding: 0 8px 8px 16px;
    font-size: 12px;
    line-height: 16px;
  }
}

/*** END Partner Card ***/

.admin-menu {
  max-width: none !important;

  .mat-mdc-menu-content {
    // prevent scrollbars showing up on anchor click
    overflow: hidden;
    padding: 8px 15px;
  }

  .mat-subheader {
    font-family: $font-primary;
    mat-icon {
      margin-right: 4px;
    }
  }

  [mat-menu-item] {
    text-overflow: initial;
    width: 100%;
  }
}

.wiz-contain {
  margin-top: 32px;
  width: 95%;

  @include respond-to('lt-lg') {
    margin-top: 16px;
  }
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

pre {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  font-family: monospace;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

/* dock */
.dock {
  a {
    color: $blue-dk;
    font-size: 16px;
    font-weight: 700;
    &:hover {
      color: $blue !important;
    }
    text-decoration: none;
    cursor: pointer;
  }

  .green {
    background-color: #599959;
    color: white;
  }

  .yellow {
    background-color: #eac152;
    color: white;
  }

  .orange {
    background-color: rgb(255, 128, 0);
    color: white;
  }

  .red {
    background-color: #c12026;
    color: white;
  }

  .default {
    background-color: lightgray;
    color: black;
  }

  .count {
    display: inline-flex;
    padding: 7px 12px;
    border-radius: 16px;
    align-items: center;
    cursor: default;
    min-height: 17px;
    height: 1px;
    font-size: 14px;
    font-weight: 500;
  }

  .multiple-dock-items {
    margin: 5px 0px;
  }

  .mat-mdc-card-header-text {
    width: 100%;
  }

  mat-card-title {
    // won't need this if we ever add dock buttons (refresh, etc)
    padding-bottom: 6px;
  }
}

mat-card.chart-dock {
  padding: 0 !important;

  mat-card-header {
    background: $blue-dk;
    border-radius: 4px 4px 0 0;
    padding-top: 7px;
  }

  mat-card-title {
    color: white;
    padding-bottom: 6px;
    margin-bottom: 0 !important;
    mat-icon {
      line-height: 18px;
      vertical-align: middle;
    }
  }

  mat-card-content {
    padding: 8px 16px !important;
  }

  .chart-select {
    margin: 20px;
    margin-bottom: -20px;
  }
}

.margin-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.margin-auto {
  margin: auto;
}

mat-chip-row {
  font-weight: 500;
}

.snapshot {
  height: 100%;
  .number {
    font-size: 2.5em;
  }
}

/* end dock */

/*mat table for mobile*/

@media screen and (max-width: 960px) {
  // Removing Table Header
  [mat-header-row] {
    display: none;
  }

  [mat-table] {
    border: 0;
    vertical-align: middle;
  }

  [mat-table] caption {
    font-size: 1em;
  }

  [mat-table] [mat-row] {
    border-bottom: 5px solid #ddd;
    display: block;
    height: 100%;
    width: 100%;
    padding: 5px;
  }

  [mat-table] [mat-cell] {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
    margin-bottom: 4%;
  }
  [mat-table] [mat-cell]:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: normal;
    font-weight: bold;
  }
  [mat-table] [mat-cell]:last-child {
    border-bottom: 0;
    padding-right: 0;
  }
  [mat-table] [mat-cell]:first-child {
    margin-top: 4%;
    padding-left: 0;
  }
}

/* end mat table for mobile */

.breadcrumb {
  color: $blue-dk;
  font-size: 1.4em;
  margin-bottom: 20px;

  a {
    cursor: pointer;
    font-weight: 600;
    color: $blue-dk;
    text-decoration: none;

    &:hover {
      color: $gold;
    }
  }

  span.home {
    font-weight: 600;
    color: $blue-dk;
  }
}

.breadcrumb + msep-alert > mat-card {
  margin-bottom: 24px;
}

/* mat-card */
mat-card.mdc-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  display: block;
  padding: 16px;
  border-radius: 4px;
  background: white;
  color: #000000de;
  font-family: Roboto, Helvetica Neue, sans-serif;
  & > :first-child {
    margin-top: 0;
  }
  & > :last-child:not(mat-card-footer),
  mat-card-content > :last-child:not(mat-card-footer) {
    margin-bottom: 0;
  }
  &:not([class*='mat-elevation-z']) {
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  }
  & mat-card-content {
    display: block;
    margin-bottom: 16px;
    padding: 0;
    font-size: 14px;
    & > :first-child {
      margin-top: 0;
    }
    &.flex {
      display: flex;
    }
  }
  & mat-card-title {
    font-weight: 500;
    margin-bottom: 8px;
    &:not(:first-child) {
      margin-top: -4px;
    }
  }
  & mat-card-subtitle {
    font-size: 14px;
    color: #0000008a;
    margin-bottom: 8px;
    &:not(:first-child) {
      margin-top: -4px;
    }
  }
  & mat-card-footer {
    display: block;
    margin: 0 -16px -16px -16px;
    line-height: 16px;
  }
  &.flex {
    display: flex;
  }
}

mat-list-item mat-icon {
  margin-right: 8px !important;
}

// light gray input box outline when not highlighted or selected
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #e0e0e0;
}

.mdc-list-item__primary-text {
  font-family: Roboto, Helvetica Neue, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
}

.mat-mdc-form-field-subscript-wrapper {
  margin-bottom: 5px;
}

.mat-mdc-form-field-error {
  margin-top: -15px;
}

mat-hint {
  color: #0009;
}

.mat-mdc-form-field
  .mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  label.mdc-floating-label--float-above {
  font-size: 14px;
}

.chart-wrap {
  position: relative;
  height: 100%;
  width: 100%;
}

.cdk-visually-hidden.cdk-focus-trap-anchor[aria-hidden='true'] {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}
