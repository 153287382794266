html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

body {
  margin: 0 auto;
  padding: 0;
}

html,
body {
  height: 100%;
}
