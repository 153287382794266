@import 'variables';
@import 'mixins';
// put GLOBAL material overrides here (508 fixes, style guide things, etc)

.using-tab mat-card:focus {
  border: 3px dashed $blue-dk;
}

.mat-mdc-raised-button {
  text-transform: uppercase;
}

.mat-mdc-paginator {
  background: none;

  .mat-mdc-paginator-container {
    @include respond-to('lt-sm') {
      justify-content: flex-start;
    }
  }

  .mat-mdc-paginator-range-label,
  .mat-mdc-paginator-page-size-label {
    @include respond-to('lt-sm') {
      margin: 0;
    }
  }
}

mat-card.alert {
  border-left: 8px solid;
  margin: 8px 0;
  cursor: default;
  padding: 5px;
  text-align: left;

  mat-icon.material-icons {
    font-size: 35px;
    margin: 0 24px 0 0px;
  }

  & mat-card-content {
    margin-bottom: 0 !important;
  }

  & mat-card-title {
    color: $gray-dk;
    font-size: 20px;
    font-weight: 700 !important;
  }

  & mat-card-subtitle {
    color: $gray-dk !important;
  }

  &.alert-info {
    cursor: default;
    border-color: $blue;
    background-color: #dbe5f0;

    mat-icon.material-icons {
      color: $blue;
    }
  }

  &.alert-success {
    border-color: $green;
    background-color: #dfebdf;

    mat-icon.material-icons {
      color: $green;
    }
  }

  &.alert-alert {
    border-color: $gold;
    background-color: #fffbf0;

    mat-icon.material-icons {
      color: $gold;
    }
  }

  &.alert-warn {
    border-color: $red;
    background-color: #fcedee;

    mat-icon.material-icons {
      color: $red;
    }
  }

  &.alert-disable {
    border-color: $gray;
    background-color: #eeeeee;

    mat-icon.material-icons {
      color: $gray;
    }
  }

  mat-icon.material-icons.close {
    font-size: 24px;
    margin: 0;
    color: $black;
  }
}

mat-card.width-600 {
  max-width: 600px;
}

mat-card-title {
  font-size: 20px !important;
  color: $blue-dk;
}

.mat-tooltip.tooltip {
  font-size: 16px; // current default is 10px :-/
  color: $white;
  background-color: $blue;
}

.msep-side-nav .mat-expansion-indicator::after {
  color: white !important;
}

mat-label + mat-radio-group {
  display: flex;
  flex-direction: column;
  margin: 6px 0;
  align-items: flex-start;

  @include respond-to('gt-sm') {
    flex-direction: row;
  }

  mat-radio-button {
    margin: 5px;
  }
}

mat-icon {
  // material now hides the overflow, which doesn't work for our current
  // layout
  overflow: visible !important;
}

.mat-mdc-button-focus-indicator:focus-visible,
.mat-mdc-button-base:focus-visible,
.mat-mdc-button:focus-visible,
.mat-mdc-button-persistent-ripple:focus-visible,
.mat-mdc-button-ripple:focus-visible,
.mat-mdc-button-touch-target:focus-visible,
.mat-mdc-select-trigger:focus-visible,
.mat-mdc-tab-ripple:focus-visible,
.mat-mdc-ripple:focus-visible,
.mat-ripple:focus-visible,
.mat-mdc-select-value:focus-visible,
.mat-mdc-tab:focus-visible,
.mat-mdc-text-field-wrapper:focus-visible,
.mat-mdc-menu-item:focus-visible,
.mat-mdc-select:focus-visible,
.mat-sort-header-container:focus-visible,
.mat-ripple.mat-mdc-menu-ripple:focus-visible {
  outline: 2px solid $black;
  outline-offset: 1px;
}

.mat-tab-body-content {
  // add just enough padding to allow mat-card, mat-table box-shadows to show
  padding: 0 2px;
  margin: 20px 0;
}
