th.icon-cell-header .mat-sort-header-container {
  justify-content: center;
}

td.mat-cell mat-icon {
  vertical-align: middle;
}

td.icon-cell {
  text-align: center;
}

tr td {
  min-height: 24px;
  padding-right: 11px;
  &::before {
    padding-left: 11px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

/* Needed to prevent horizontal scroll on grid pages */
table button .material-icons {
  overflow: hidden !important;
}

.mdc-data-table__header-cell.mat-mdc-header-cell,
.mdc-data-table__header-cell.mat-mdc-header-cell mat-checkbox .mdc-form-field {
  color: #0000008a;
  font-size: 12px;
  font-weight: 500;
}

.mat-mdc-cell.mdc-data-table__cell {
  padding: 0;
  margin-right: 11px;
  @include respond-to('gt-sm') {
    padding: 0 16px 0 16px;
  }
}
