/** COLORS & FONT FAMILIES **/
$red: #c12026;
$purple: #9361a8;
$orange: #f39f52;
$blue: #134e8f;
$blue-dk: #194867;
$blue-link: #3f51b5;
$green: #008000;
$gold: #f4c94e;
$gold-lt: #f8dc8e;
$gray-lt: #eeeeee;
$gray: #999999;
$gray-dk: #333333;
$white: #ffffff;
$black: #231f20;

$materialBackgroundGray: #fafafa;
// color used by mat-card-subtilte
$materialBlackGrey: #0000008a;
$codePink: #c6397b;

$font-default: 'Montserrat', sans-serif;
$font-primary: 'Roboto', Helvetica, Arial, 'Lucida Grande', sans-serif;
