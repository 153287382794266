html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

body {
  margin: 0 auto;
  padding: 0;
}

html,
body {
  height: 100%;
}

/** COLORS & FONT FAMILIES **/
/** COLORS & FONT FAMILIES **/
.using-tab mat-card:focus {
  border: 3px dashed #194867;
}

.mat-mdc-raised-button {
  text-transform: uppercase;
}

.mat-mdc-paginator {
  background: none;
}
@media (max-width: 599px) {
  .mat-mdc-paginator .mat-mdc-paginator-container {
    justify-content: flex-start;
  }
}
@media (max-width: 599px) {
  .mat-mdc-paginator .mat-mdc-paginator-range-label,
  .mat-mdc-paginator .mat-mdc-paginator-page-size-label {
    margin: 0;
  }
}

mat-card.alert {
  border-left: 8px solid;
  margin: 8px 0;
  cursor: default;
  padding: 5px;
  text-align: left;
}
mat-card.alert mat-icon.material-icons {
  font-size: 35px;
  margin: 0 24px 0 0px;
}
mat-card.alert mat-card-content {
  margin-bottom: 0 !important;
}
mat-card.alert mat-card-title {
  color: #333333;
  font-size: 20px;
  font-weight: 700 !important;
}
mat-card.alert mat-card-subtitle {
  color: #333333 !important;
}
mat-card.alert.alert-info {
  cursor: default;
  border-color: #134e8f;
  background-color: #dbe5f0;
}
mat-card.alert.alert-info mat-icon.material-icons {
  color: #134e8f;
}
mat-card.alert.alert-success {
  border-color: #008000;
  background-color: #dfebdf;
}
mat-card.alert.alert-success mat-icon.material-icons {
  color: #008000;
}
mat-card.alert.alert-alert {
  border-color: #f4c94e;
  background-color: #fffbf0;
}
mat-card.alert.alert-alert mat-icon.material-icons {
  color: #f4c94e;
}
mat-card.alert.alert-warn {
  border-color: #c12026;
  background-color: #fcedee;
}
mat-card.alert.alert-warn mat-icon.material-icons {
  color: #c12026;
}
mat-card.alert.alert-disable {
  border-color: #999999;
  background-color: #eeeeee;
}
mat-card.alert.alert-disable mat-icon.material-icons {
  color: #999999;
}
mat-card.alert mat-icon.material-icons.close {
  font-size: 24px;
  margin: 0;
  color: #231f20;
}

mat-card.width-600 {
  max-width: 600px;
}

mat-card-title {
  font-size: 20px !important;
  color: #194867;
}

.mat-tooltip.tooltip {
  font-size: 16px;
  color: #ffffff;
  background-color: #134e8f;
}

.msep-side-nav .mat-expansion-indicator::after {
  color: white !important;
}

mat-label + mat-radio-group {
  display: flex;
  flex-direction: column;
  margin: 6px 0;
  align-items: flex-start;
}
@media (min-width: 960px) {
  mat-label + mat-radio-group {
    flex-direction: row;
  }
}
mat-label + mat-radio-group mat-radio-button {
  margin: 5px;
}

mat-icon {
  overflow: visible !important;
}

.mat-mdc-button-focus-indicator:focus-visible,
.mat-mdc-button-base:focus-visible,
.mat-mdc-button:focus-visible,
.mat-mdc-button-persistent-ripple:focus-visible,
.mat-mdc-button-ripple:focus-visible,
.mat-mdc-button-touch-target:focus-visible,
.mat-mdc-select-trigger:focus-visible,
.mat-mdc-tab-ripple:focus-visible,
.mat-mdc-ripple:focus-visible,
.mat-ripple:focus-visible,
.mat-mdc-select-value:focus-visible,
.mat-mdc-tab:focus-visible,
.mat-mdc-text-field-wrapper:focus-visible,
.mat-mdc-menu-item:focus-visible,
.mat-mdc-select:focus-visible,
.mat-sort-header-container:focus-visible,
.mat-ripple.mat-mdc-menu-ripple:focus-visible {
  outline: 2px solid #231f20;
  outline-offset: 1px;
}

.mat-tab-body-content {
  padding: 0 2px;
  margin: 20px 0;
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, sans-serif;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 16px / 24px Roboto, sans-serif;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Roboto, sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto, sans-serif;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: 0.0178571429em;
  --mat-switch-label-text-weight: 400;
}

html {
  --mat-radio-label-text-font: Roboto, sans-serif;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: 0.0178571429em;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mat-checkbox-label-text-font: Roboto, sans-serif;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: 0.0178571429em;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #134e8f;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #008800;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #c12026;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #134e8f;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #134e8f;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #008800;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #008800;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #008800;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #008800;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c12026;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c12026;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #134e8f;
  --mdc-linear-progress-track-color: rgba(19, 78, 143, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #008800;
  --mdc-linear-progress-track-color: rgba(0, 136, 0, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c12026;
  --mdc-linear-progress-track-color: rgba(193, 32, 38, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #134e8f;
  --mdc-filled-text-field-focus-active-indicator-color: #134e8f;
  --mdc-filled-text-field-focus-label-text-color: rgba(19, 78, 143, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c12026;
  --mdc-filled-text-field-error-focus-label-text-color: #c12026;
  --mdc-filled-text-field-error-label-text-color: #c12026;
  --mdc-filled-text-field-error-caret-color: #c12026;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c12026;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c12026;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c12026;
  --mdc-outlined-text-field-caret-color: #134e8f;
  --mdc-outlined-text-field-focus-outline-color: #134e8f;
  --mdc-outlined-text-field-focus-label-text-color: rgba(19, 78, 143, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c12026;
  --mdc-outlined-text-field-error-focus-label-text-color: #c12026;
  --mdc-outlined-text-field-error-label-text-color: #c12026;
  --mdc-outlined-text-field-error-hover-label-text-color: #c12026;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c12026;
  --mdc-outlined-text-field-error-hover-outline-color: #c12026;
  --mdc-outlined-text-field-error-outline-color: #c12026;
  --mat-form-field-focus-select-arrow-color: rgba(19, 78, 143, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c12026;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #008800;
  --mdc-filled-text-field-focus-active-indicator-color: #008800;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 136, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #008800;
  --mdc-outlined-text-field-focus-outline-color: #008800;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 136, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(0, 136, 0, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c12026;
  --mdc-filled-text-field-focus-active-indicator-color: #c12026;
  --mdc-filled-text-field-focus-label-text-color: rgba(193, 32, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #c12026;
  --mdc-outlined-text-field-focus-outline-color: #c12026;
  --mdc-outlined-text-field-focus-label-text-color: rgba(193, 32, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(193, 32, 38, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(19, 78, 143, 0.87);
  --mat-select-invalid-arrow-color: rgba(193, 32, 38, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 136, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(193, 32, 38, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(193, 32, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(193, 32, 38, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #134e8f;
  --mdc-chip-elevated-selected-container-color: #134e8f;
  --mdc-chip-elevated-disabled-container-color: #134e8f;
  --mdc-chip-flat-disabled-selected-container-color: #134e8f;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #008800;
  --mdc-chip-elevated-selected-container-color: #008800;
  --mdc-chip-elevated-disabled-container-color: #008800;
  --mdc-chip-flat-disabled-selected-container-color: #008800;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #c12026;
  --mdc-chip-elevated-selected-container-color: #c12026;
  --mdc-chip-elevated-disabled-container-color: #c12026;
  --mdc-chip-flat-disabled-selected-container-color: #c12026;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #114787;
  --mdc-switch-selected-handle-color: #114787;
  --mdc-switch-selected-hover-state-layer-color: #114787;
  --mdc-switch-selected-pressed-state-layer-color: #114787;
  --mdc-switch-selected-focus-handle-color: #062560;
  --mdc-switch-selected-hover-handle-color: #062560;
  --mdc-switch-selected-pressed-handle-color: #062560;
  --mdc-switch-selected-focus-track-color: #5a83b1;
  --mdc-switch-selected-hover-track-color: #5a83b1;
  --mdc-switch-selected-pressed-track-color: #5a83b1;
  --mdc-switch-selected-track-color: #5a83b1;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: green;
  --mdc-switch-selected-handle-color: green;
  --mdc-switch-selected-hover-state-layer-color: green;
  --mdc-switch-selected-pressed-state-layer-color: green;
  --mdc-switch-selected-focus-handle-color: #005800;
  --mdc-switch-selected-hover-handle-color: #005800;
  --mdc-switch-selected-pressed-handle-color: #005800;
  --mdc-switch-selected-focus-track-color: #4dac4d;
  --mdc-switch-selected-hover-track-color: #4dac4d;
  --mdc-switch-selected-pressed-track-color: #4dac4d;
  --mdc-switch-selected-track-color: #4dac4d;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #bb1c22;
  --mdc-switch-selected-handle-color: #bb1c22;
  --mdc-switch-selected-hover-state-layer-color: #bb1c22;
  --mdc-switch-selected-pressed-state-layer-color: #bb1c22;
  --mdc-switch-selected-focus-handle-color: #9e0b0d;
  --mdc-switch-selected-hover-handle-color: #9e0b0d;
  --mdc-switch-selected-pressed-handle-color: #9e0b0d;
  --mdc-switch-selected-focus-track-color: #d46367;
  --mdc-switch-selected-hover-track-color: #d46367;
  --mdc-switch-selected-pressed-track-color: #d46367;
  --mdc-switch-selected-track-color: #d46367;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #134e8f;
  --mdc-radio-selected-hover-icon-color: #134e8f;
  --mdc-radio-selected-icon-color: #134e8f;
  --mdc-radio-selected-pressed-icon-color: #134e8f;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #134e8f;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #008800;
  --mdc-radio-selected-hover-icon-color: #008800;
  --mdc-radio-selected-icon-color: #008800;
  --mdc-radio-selected-pressed-icon-color: #008800;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #008800;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c12026;
  --mdc-radio-selected-hover-icon-color: #c12026;
  --mdc-radio-selected-icon-color: #c12026;
  --mdc-radio-selected-pressed-icon-color: #c12026;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c12026;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #134e8f;
  --mdc-slider-focus-handle-color: #134e8f;
  --mdc-slider-hover-handle-color: #134e8f;
  --mdc-slider-active-track-color: #134e8f;
  --mdc-slider-inactive-track-color: #134e8f;
  --mdc-slider-with-tick-marks-inactive-container-color: #134e8f;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #134e8f;
  --mat-slider-hover-state-layer-color: rgba(19, 78, 143, 0.05);
  --mat-slider-focus-state-layer-color: rgba(19, 78, 143, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #008800;
  --mdc-slider-focus-handle-color: #008800;
  --mdc-slider-hover-handle-color: #008800;
  --mdc-slider-active-track-color: #008800;
  --mdc-slider-inactive-track-color: #008800;
  --mdc-slider-with-tick-marks-inactive-container-color: #008800;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #008800;
  --mat-slider-hover-state-layer-color: rgba(0, 136, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 136, 0, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #c12026;
  --mdc-slider-focus-handle-color: #c12026;
  --mdc-slider-hover-handle-color: #c12026;
  --mdc-slider-active-track-color: #c12026;
  --mdc-slider-inactive-track-color: #c12026;
  --mdc-slider-with-tick-marks-inactive-container-color: #c12026;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #c12026;
  --mat-slider-hover-state-layer-color: rgba(193, 32, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(193, 32, 38, 0.2);
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #134e8f;
  --mdc-radio-selected-hover-icon-color: #134e8f;
  --mdc-radio-selected-icon-color: #134e8f;
  --mdc-radio-selected-pressed-icon-color: #134e8f;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #008800;
  --mdc-radio-selected-hover-icon-color: #008800;
  --mdc-radio-selected-icon-color: #008800;
  --mdc-radio-selected-pressed-icon-color: #008800;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c12026;
  --mdc-radio-selected-hover-icon-color: #c12026;
  --mdc-radio-selected-icon-color: #c12026;
  --mdc-radio-selected-pressed-icon-color: #c12026;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #134e8f;
  --mdc-checkbox-selected-hover-icon-color: #134e8f;
  --mdc-checkbox-selected-icon-color: #134e8f;
  --mdc-checkbox-selected-pressed-icon-color: #134e8f;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #134e8f;
  --mdc-checkbox-selected-hover-state-layer-color: #134e8f;
  --mdc-checkbox-selected-pressed-state-layer-color: #134e8f;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #008800;
  --mdc-checkbox-selected-hover-icon-color: #008800;
  --mdc-checkbox-selected-icon-color: #008800;
  --mdc-checkbox-selected-pressed-icon-color: #008800;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #008800;
  --mdc-checkbox-selected-hover-state-layer-color: #008800;
  --mdc-checkbox-selected-pressed-state-layer-color: #008800;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #c12026;
  --mdc-checkbox-selected-hover-icon-color: #c12026;
  --mdc-checkbox-selected-icon-color: #c12026;
  --mdc-checkbox-selected-pressed-icon-color: #c12026;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c12026;
  --mdc-checkbox-selected-hover-state-layer-color: #c12026;
  --mdc-checkbox-selected-pressed-state-layer-color: #c12026;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #134e8f;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #134e8f;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #134e8f;
  --mat-tab-header-active-ripple-color: #134e8f;
  --mat-tab-header-inactive-ripple-color: #134e8f;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #134e8f;
  --mat-tab-header-active-hover-label-text-color: #134e8f;
  --mat-tab-header-active-focus-indicator-color: #134e8f;
  --mat-tab-header-active-hover-indicator-color: #134e8f;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #008800;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #008800;
  --mat-tab-header-active-ripple-color: #008800;
  --mat-tab-header-inactive-ripple-color: #008800;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #008800;
  --mat-tab-header-active-hover-label-text-color: #008800;
  --mat-tab-header-active-focus-indicator-color: #008800;
  --mat-tab-header-active-hover-indicator-color: #008800;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c12026;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c12026;
  --mat-tab-header-active-ripple-color: #c12026;
  --mat-tab-header-inactive-ripple-color: #c12026;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c12026;
  --mat-tab-header-active-hover-label-text-color: #c12026;
  --mat-tab-header-active-focus-indicator-color: #c12026;
  --mat-tab-header-active-hover-indicator-color: #c12026;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #134e8f;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #008800;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c12026;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #008800;
  --mdc-checkbox-selected-hover-icon-color: #008800;
  --mdc-checkbox-selected-icon-color: #008800;
  --mdc-checkbox-selected-pressed-icon-color: #008800;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #008800;
  --mdc-checkbox-selected-hover-state-layer-color: #008800;
  --mdc-checkbox-selected-pressed-state-layer-color: #008800;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #134e8f;
  --mdc-checkbox-selected-hover-icon-color: #134e8f;
  --mdc-checkbox-selected-icon-color: #134e8f;
  --mdc-checkbox-selected-pressed-icon-color: #134e8f;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #134e8f;
  --mdc-checkbox-selected-hover-state-layer-color: #134e8f;
  --mdc-checkbox-selected-pressed-state-layer-color: #134e8f;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #c12026;
  --mdc-checkbox-selected-hover-icon-color: #c12026;
  --mdc-checkbox-selected-icon-color: #c12026;
  --mdc-checkbox-selected-pressed-icon-color: #c12026;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c12026;
  --mdc-checkbox-selected-hover-state-layer-color: #c12026;
  --mdc-checkbox-selected-pressed-state-layer-color: #c12026;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #134e8f;
  --mat-text-button-state-layer-color: #134e8f;
  --mat-text-button-ripple-color: rgba(19, 78, 143, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #008800;
  --mat-text-button-state-layer-color: #008800;
  --mat-text-button-ripple-color: rgba(0, 136, 0, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c12026;
  --mat-text-button-state-layer-color: #c12026;
  --mat-text-button-ripple-color: rgba(193, 32, 38, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #134e8f;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #008800;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c12026;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #134e8f;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #008800;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c12026;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #134e8f;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #134e8f;
  --mat-outlined-button-ripple-color: rgba(19, 78, 143, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #008800;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #008800;
  --mat-outlined-button-ripple-color: rgba(0, 136, 0, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c12026;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c12026;
  --mat-outlined-button-ripple-color: rgba(193, 32, 38, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #134e8f;
  --mat-icon-button-state-layer-color: #134e8f;
  --mat-icon-button-ripple-color: rgba(19, 78, 143, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #008800;
  --mat-icon-button-state-layer-color: #008800;
  --mat-icon-button-ripple-color: rgba(0, 136, 0, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c12026;
  --mat-icon-button-state-layer-color: #c12026;
  --mat-icon-button-ripple-color: rgba(193, 32, 38, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #134e8f;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #008800;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c12026;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #134e8f;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #008800;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c12026;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #008800;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #134e8f;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #008800;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c12026;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #134e8f;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #008800;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #c12026;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #134e8f;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(19, 78, 143, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(19, 78, 143, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(19, 78, 143, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #134e8f;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(19, 78, 143, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #008800;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 136, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 136, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 136, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 136, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #c12026;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(193, 32, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(193, 32, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(193, 32, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(193, 32, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #008800;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c12026;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #134e8f;
}
.mat-icon.mat-accent {
  --mat-icon-color: #008800;
}
.mat-icon.mat-warn {
  --mat-icon-color: #c12026;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #134e8f;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #134e8f;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #134e8f;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c12026;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c12026;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #008800;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #008800;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #008800;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #c12026;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #c12026;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #c12026;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #134e8f;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #008800;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c12026;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

th.icon-cell-header .mat-sort-header-container {
  justify-content: center;
}

td.mat-cell mat-icon {
  vertical-align: middle;
}

td.icon-cell {
  text-align: center;
}

tr td {
  min-height: 24px;
  padding-right: 11px;
}
tr td::before {
  padding-left: 11px;
}
tr td:last-of-type {
  margin-bottom: 0;
}

/* Needed to prevent horizontal scroll on grid pages */
table button .material-icons {
  overflow: hidden !important;
}

.mdc-data-table__header-cell.mat-mdc-header-cell,
.mdc-data-table__header-cell.mat-mdc-header-cell mat-checkbox .mdc-form-field {
  color: rgba(0, 0, 0, 0.5411764706);
  font-size: 12px;
  font-weight: 500;
}

.mat-mdc-cell.mdc-data-table__cell {
  padding: 0;
  margin-right: 11px;
}
@media (min-width: 960px) {
  .mat-mdc-cell.mdc-data-table__cell {
    padding: 0 16px 0 16px;
  }
}

/** COLORS & FONT FAMILIES **/
.flex {
  display: flex;
  box-sizing: border-box;
}

.flex-33-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
}

.flex-1 {
  flex: 1 1 1%;
  max-width: 1%;
  box-sizing: border-box;
}

.flex-2 {
  flex: 1 1 2%;
  max-width: 2%;
  box-sizing: border-box;
}

.flex-3 {
  flex: 1 1 3%;
  max-width: 3%;
  box-sizing: border-box;
}

.flex-4 {
  flex: 1 1 4%;
  max-width: 4%;
  box-sizing: border-box;
}

.flex-5 {
  flex: 1 1 5%;
  max-width: 5%;
  box-sizing: border-box;
}

.flex-6 {
  flex: 1 1 6%;
  max-width: 6%;
  box-sizing: border-box;
}

.flex-7 {
  flex: 1 1 7%;
  max-width: 7%;
  box-sizing: border-box;
}

.flex-8 {
  flex: 1 1 8%;
  max-width: 8%;
  box-sizing: border-box;
}

.flex-9 {
  flex: 1 1 9%;
  max-width: 9%;
  box-sizing: border-box;
}

.flex-10 {
  flex: 1 1 10%;
  max-width: 10%;
  box-sizing: border-box;
}

.flex-11 {
  flex: 1 1 11%;
  max-width: 11%;
  box-sizing: border-box;
}

.flex-12 {
  flex: 1 1 12%;
  max-width: 12%;
  box-sizing: border-box;
}

.flex-13 {
  flex: 1 1 13%;
  max-width: 13%;
  box-sizing: border-box;
}

.flex-14 {
  flex: 1 1 14%;
  max-width: 14%;
  box-sizing: border-box;
}

.flex-15 {
  flex: 1 1 15%;
  max-width: 15%;
  box-sizing: border-box;
}

.flex-16 {
  flex: 1 1 16%;
  max-width: 16%;
  box-sizing: border-box;
}

.flex-17 {
  flex: 1 1 17%;
  max-width: 17%;
  box-sizing: border-box;
}

.flex-18 {
  flex: 1 1 18%;
  max-width: 18%;
  box-sizing: border-box;
}

.flex-19 {
  flex: 1 1 19%;
  max-width: 19%;
  box-sizing: border-box;
}

.flex-20 {
  flex: 1 1 20%;
  max-width: 20%;
  box-sizing: border-box;
}

.flex-21 {
  flex: 1 1 21%;
  max-width: 21%;
  box-sizing: border-box;
}

.flex-22 {
  flex: 1 1 22%;
  max-width: 22%;
  box-sizing: border-box;
}

.flex-23 {
  flex: 1 1 23%;
  max-width: 23%;
  box-sizing: border-box;
}

.flex-24 {
  flex: 1 1 24%;
  max-width: 24%;
  box-sizing: border-box;
}

.flex-25 {
  flex: 1 1 25%;
  max-width: 25%;
  box-sizing: border-box;
}

.flex-26 {
  flex: 1 1 26%;
  max-width: 26%;
  box-sizing: border-box;
}

.flex-27 {
  flex: 1 1 27%;
  max-width: 27%;
  box-sizing: border-box;
}

.flex-28 {
  flex: 1 1 28%;
  max-width: 28%;
  box-sizing: border-box;
}

.flex-29 {
  flex: 1 1 29%;
  max-width: 29%;
  box-sizing: border-box;
}

.flex-30 {
  flex: 1 1 30%;
  max-width: 30%;
  box-sizing: border-box;
}

.flex-31 {
  flex: 1 1 31%;
  max-width: 31%;
  box-sizing: border-box;
}

.flex-32 {
  flex: 1 1 32%;
  max-width: 32%;
  box-sizing: border-box;
}

.flex-33 {
  flex: 1 1 33%;
  max-width: 33%;
  box-sizing: border-box;
}

.flex-34 {
  flex: 1 1 34%;
  max-width: 34%;
  box-sizing: border-box;
}

.flex-35 {
  flex: 1 1 35%;
  max-width: 35%;
  box-sizing: border-box;
}

.flex-36 {
  flex: 1 1 36%;
  max-width: 36%;
  box-sizing: border-box;
}

.flex-37 {
  flex: 1 1 37%;
  max-width: 37%;
  box-sizing: border-box;
}

.flex-38 {
  flex: 1 1 38%;
  max-width: 38%;
  box-sizing: border-box;
}

.flex-39 {
  flex: 1 1 39%;
  max-width: 39%;
  box-sizing: border-box;
}

.flex-40 {
  flex: 1 1 40%;
  max-width: 40%;
  box-sizing: border-box;
}

.flex-41 {
  flex: 1 1 41%;
  max-width: 41%;
  box-sizing: border-box;
}

.flex-42 {
  flex: 1 1 42%;
  max-width: 42%;
  box-sizing: border-box;
}

.flex-43 {
  flex: 1 1 43%;
  max-width: 43%;
  box-sizing: border-box;
}

.flex-44 {
  flex: 1 1 44%;
  max-width: 44%;
  box-sizing: border-box;
}

.flex-45 {
  flex: 1 1 45%;
  max-width: 45%;
  box-sizing: border-box;
}

.flex-46 {
  flex: 1 1 46%;
  max-width: 46%;
  box-sizing: border-box;
}

.flex-47 {
  flex: 1 1 47%;
  max-width: 47%;
  box-sizing: border-box;
}

.flex-48 {
  flex: 1 1 48%;
  max-width: 48%;
  box-sizing: border-box;
}

.flex-49 {
  flex: 1 1 49%;
  max-width: 49%;
  box-sizing: border-box;
}

.flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  box-sizing: border-box;
}

.flex-51 {
  flex: 1 1 51%;
  max-width: 51%;
  box-sizing: border-box;
}

.flex-52 {
  flex: 1 1 52%;
  max-width: 52%;
  box-sizing: border-box;
}

.flex-53 {
  flex: 1 1 53%;
  max-width: 53%;
  box-sizing: border-box;
}

.flex-54 {
  flex: 1 1 54%;
  max-width: 54%;
  box-sizing: border-box;
}

.flex-55 {
  flex: 1 1 55%;
  max-width: 55%;
  box-sizing: border-box;
}

.flex-56 {
  flex: 1 1 56%;
  max-width: 56%;
  box-sizing: border-box;
}

.flex-57 {
  flex: 1 1 57%;
  max-width: 57%;
  box-sizing: border-box;
}

.flex-58 {
  flex: 1 1 58%;
  max-width: 58%;
  box-sizing: border-box;
}

.flex-59 {
  flex: 1 1 59%;
  max-width: 59%;
  box-sizing: border-box;
}

.flex-60 {
  flex: 1 1 60%;
  max-width: 60%;
  box-sizing: border-box;
}

.flex-61 {
  flex: 1 1 61%;
  max-width: 61%;
  box-sizing: border-box;
}

.flex-62 {
  flex: 1 1 62%;
  max-width: 62%;
  box-sizing: border-box;
}

.flex-63 {
  flex: 1 1 63%;
  max-width: 63%;
  box-sizing: border-box;
}

.flex-64 {
  flex: 1 1 64%;
  max-width: 64%;
  box-sizing: border-box;
}

.flex-65 {
  flex: 1 1 65%;
  max-width: 65%;
  box-sizing: border-box;
}

.flex-66 {
  flex: 1 1 66%;
  max-width: 66%;
  box-sizing: border-box;
}

.flex-67 {
  flex: 1 1 67%;
  max-width: 67%;
  box-sizing: border-box;
}

.flex-68 {
  flex: 1 1 68%;
  max-width: 68%;
  box-sizing: border-box;
}

.flex-69 {
  flex: 1 1 69%;
  max-width: 69%;
  box-sizing: border-box;
}

.flex-70 {
  flex: 1 1 70%;
  max-width: 70%;
  box-sizing: border-box;
}

.flex-71 {
  flex: 1 1 71%;
  max-width: 71%;
  box-sizing: border-box;
}

.flex-72 {
  flex: 1 1 72%;
  max-width: 72%;
  box-sizing: border-box;
}

.flex-73 {
  flex: 1 1 73%;
  max-width: 73%;
  box-sizing: border-box;
}

.flex-74 {
  flex: 1 1 74%;
  max-width: 74%;
  box-sizing: border-box;
}

.flex-75 {
  flex: 1 1 75%;
  max-width: 75%;
  box-sizing: border-box;
}

.flex-76 {
  flex: 1 1 76%;
  max-width: 76%;
  box-sizing: border-box;
}

.flex-77 {
  flex: 1 1 77%;
  max-width: 77%;
  box-sizing: border-box;
}

.flex-78 {
  flex: 1 1 78%;
  max-width: 78%;
  box-sizing: border-box;
}

.flex-79 {
  flex: 1 1 79%;
  max-width: 79%;
  box-sizing: border-box;
}

.flex-80 {
  flex: 1 1 80%;
  max-width: 80%;
  box-sizing: border-box;
}

.flex-81 {
  flex: 1 1 81%;
  max-width: 81%;
  box-sizing: border-box;
}

.flex-82 {
  flex: 1 1 82%;
  max-width: 82%;
  box-sizing: border-box;
}

.flex-83 {
  flex: 1 1 83%;
  max-width: 83%;
  box-sizing: border-box;
}

.flex-84 {
  flex: 1 1 84%;
  max-width: 84%;
  box-sizing: border-box;
}

.flex-85 {
  flex: 1 1 85%;
  max-width: 85%;
  box-sizing: border-box;
}

.flex-86 {
  flex: 1 1 86%;
  max-width: 86%;
  box-sizing: border-box;
}

.flex-87 {
  flex: 1 1 87%;
  max-width: 87%;
  box-sizing: border-box;
}

.flex-88 {
  flex: 1 1 88%;
  max-width: 88%;
  box-sizing: border-box;
}

.flex-89 {
  flex: 1 1 89%;
  max-width: 89%;
  box-sizing: border-box;
}

.flex-90 {
  flex: 1 1 90%;
  max-width: 90%;
  box-sizing: border-box;
}

.flex-91 {
  flex: 1 1 91%;
  max-width: 91%;
  box-sizing: border-box;
}

.flex-92 {
  flex: 1 1 92%;
  max-width: 92%;
  box-sizing: border-box;
}

.flex-93 {
  flex: 1 1 93%;
  max-width: 93%;
  box-sizing: border-box;
}

.flex-94 {
  flex: 1 1 94%;
  max-width: 94%;
  box-sizing: border-box;
}

.flex-95 {
  flex: 1 1 95%;
  max-width: 95%;
  box-sizing: border-box;
}

.flex-96 {
  flex: 1 1 96%;
  max-width: 96%;
  box-sizing: border-box;
}

.flex-97 {
  flex: 1 1 97%;
  max-width: 97%;
  box-sizing: border-box;
}

.flex-98 {
  flex: 1 1 98%;
  max-width: 98%;
  box-sizing: border-box;
}

.flex-99 {
  flex: 1 1 99%;
  max-width: 99%;
  box-sizing: border-box;
}

.flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  box-sizing: border-box;
}

@media (max-width: 599px) {
  .flex-xs-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-xs-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-xs-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-xs-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-xs-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-xs-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-xs-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-xs-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-xs-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-xs-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-xs-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-xs-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-xs-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-xs-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-xs-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-xs-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-xs-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-xs-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-xs-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-xs-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-xs-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-xs-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-xs-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-xs-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-xs-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-xs-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-xs-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-xs-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-xs-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-xs-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-xs-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-xs-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-xs-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-xs-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-xs-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-xs-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-xs-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-xs-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-xs-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-xs-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-xs-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-xs-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-xs-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-xs-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-xs-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-xs-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-xs-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-xs-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-xs-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-xs-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-xs-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-xs-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-xs-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-xs-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-xs-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-xs-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-xs-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-xs-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-xs-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-xs-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-xs-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-xs-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-xs-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-xs-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-xs-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-xs-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-xs-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-xs-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-xs-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-xs-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-xs-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-xs-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-xs-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-xs-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-xs-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-xs-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-xs-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-xs-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-xs-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-xs-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-xs-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 600px) {
  .flex-sm-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-sm-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-sm-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-sm-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-sm-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-sm-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-sm-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-sm-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-sm-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-sm-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-sm-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-sm-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-sm-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-sm-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-sm-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-sm-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-sm-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-sm-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-sm-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-sm-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-sm-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-sm-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-sm-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-sm-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-sm-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-sm-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-sm-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-sm-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-sm-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-sm-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-sm-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-sm-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-sm-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-sm-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-sm-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-sm-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-sm-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-sm-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-sm-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-sm-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-sm-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-sm-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-sm-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-sm-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-sm-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-sm-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-sm-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-sm-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-sm-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-sm-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-sm-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-sm-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-sm-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-sm-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-sm-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-sm-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-sm-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-sm-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-sm-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-sm-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-sm-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-sm-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-sm-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-sm-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-sm-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-sm-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-sm-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-sm-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-sm-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-sm-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-sm-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-sm-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-sm-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-sm-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-sm-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-sm-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-sm-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-sm-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-sm-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-sm-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-sm-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 960px) {
  .flex-md-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-md-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-md-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-md-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-md-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-md-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-md-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-md-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-md-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-md-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-md-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-md-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-md-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-md-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-md-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-md-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-md-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-md-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-md-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-md-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-md-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-md-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-md-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-md-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-md-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-md-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-md-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-md-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-md-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-md-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-md-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-md-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-md-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-md-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-md-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-md-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-md-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-md-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-md-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-md-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-md-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-md-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-md-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-md-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-md-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-md-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-md-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-md-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-md-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-md-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-md-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-md-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-md-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-md-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-md-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-md-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-md-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-md-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-md-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-md-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-md-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-md-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-md-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-md-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-md-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-md-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-md-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-md-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-md-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-md-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-md-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-md-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-md-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-md-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-md-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-md-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-md-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-md-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-md-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-md-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-md-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 1280px) {
  .flex-lg-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-lg-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-lg-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-lg-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-lg-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-lg-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-lg-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-lg-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-lg-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-lg-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-lg-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-lg-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-lg-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-lg-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-lg-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-lg-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-lg-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-lg-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-lg-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-lg-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-lg-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-lg-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-lg-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-lg-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-lg-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-lg-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-lg-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-lg-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-lg-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-lg-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-lg-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-lg-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-lg-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-lg-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-lg-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-lg-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-lg-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-lg-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-lg-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-lg-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-lg-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-lg-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-lg-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-lg-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-lg-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-lg-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-lg-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-lg-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-lg-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-lg-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-lg-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-lg-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-lg-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-lg-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-lg-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-lg-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-lg-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-lg-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-lg-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-lg-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-lg-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-lg-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-lg-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-lg-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-lg-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-lg-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-lg-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-lg-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-lg-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-lg-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-lg-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-lg-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-lg-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-lg-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-lg-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-lg-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-lg-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-lg-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-lg-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-lg-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-lg-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 1920px) {
  .flex-xl-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-xl-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-xl-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-xl-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-xl-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-xl-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-xl-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-xl-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-xl-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-xl-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-xl-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-xl-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-xl-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-xl-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-xl-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-xl-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-xl-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-xl-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-xl-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-xl-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-xl-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-xl-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-xl-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-xl-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-xl-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-xl-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-xl-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-xl-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-xl-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-xl-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-xl-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-xl-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-xl-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-xl-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-xl-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-xl-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-xl-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-xl-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-xl-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-xl-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-xl-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-xl-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-xl-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-xl-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-xl-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-xl-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-xl-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-xl-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-xl-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-xl-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-xl-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-xl-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-xl-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-xl-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-xl-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-xl-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-xl-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-xl-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-xl-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-xl-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-xl-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-xl-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-xl-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-xl-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-xl-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-xl-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-xl-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-xl-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-xl-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-xl-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-xl-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-xl-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-xl-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-xl-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-xl-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-xl-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-xl-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-xl-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-xl-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-xl-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-xl-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (max-width: 599px) {
  .flex-lt-sm-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-lt-sm-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-lt-sm-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-lt-sm-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-lt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-lt-sm-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-lt-sm-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-lt-sm-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-lt-sm-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-lt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-lt-sm-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-lt-sm-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-lt-sm-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-lt-sm-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-lt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-lt-sm-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-lt-sm-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-lt-sm-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-lt-sm-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-lt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-lt-sm-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-lt-sm-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-lt-sm-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-lt-sm-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-lt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-lt-sm-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-lt-sm-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-lt-sm-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-lt-sm-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-lt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-lt-sm-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-lt-sm-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-lt-sm-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-lt-sm-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-lt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-lt-sm-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-lt-sm-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-lt-sm-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-lt-sm-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-lt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-lt-sm-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-lt-sm-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-lt-sm-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-lt-sm-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-lt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-lt-sm-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-lt-sm-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-lt-sm-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-lt-sm-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-lt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-lt-sm-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-lt-sm-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-lt-sm-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-lt-sm-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-lt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-lt-sm-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-lt-sm-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-lt-sm-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-lt-sm-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-lt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-lt-sm-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-lt-sm-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-lt-sm-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-lt-sm-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-lt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-lt-sm-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-lt-sm-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-lt-sm-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-lt-sm-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-lt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-lt-sm-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-lt-sm-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-lt-sm-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-lt-sm-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-lt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-lt-sm-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-lt-sm-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-lt-sm-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-lt-sm-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-lt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-lt-sm-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-lt-sm-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-lt-sm-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-lt-sm-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-lt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-lt-sm-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-lt-sm-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-lt-sm-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-lt-sm-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-lt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-lt-sm-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-lt-sm-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-lt-sm-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-lt-sm-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-lt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-lt-sm-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-lt-sm-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-lt-sm-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-lt-sm-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-lt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-lt-sm-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (max-width: 959px) {
  .flex-lt-md-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-lt-md-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-lt-md-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-lt-md-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-lt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-lt-md-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-lt-md-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-lt-md-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-lt-md-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-lt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-lt-md-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-lt-md-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-lt-md-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-lt-md-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-lt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-lt-md-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-lt-md-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-lt-md-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-lt-md-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-lt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-lt-md-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-lt-md-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-lt-md-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-lt-md-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-lt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-lt-md-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-lt-md-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-lt-md-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-lt-md-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-lt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-lt-md-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-lt-md-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-lt-md-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-lt-md-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-lt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-lt-md-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-lt-md-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-lt-md-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-lt-md-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-lt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-lt-md-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-lt-md-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-lt-md-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-lt-md-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-lt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-lt-md-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-lt-md-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-lt-md-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-lt-md-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-lt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-lt-md-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-lt-md-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-lt-md-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-lt-md-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-lt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-lt-md-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-lt-md-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-lt-md-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-lt-md-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-lt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-lt-md-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-lt-md-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-lt-md-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-lt-md-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-lt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-lt-md-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-lt-md-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-lt-md-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-lt-md-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-lt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-lt-md-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-lt-md-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-lt-md-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-lt-md-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-lt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-lt-md-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-lt-md-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-lt-md-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-lt-md-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-lt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-lt-md-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-lt-md-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-lt-md-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-lt-md-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-lt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-lt-md-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-lt-md-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-lt-md-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-lt-md-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-lt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-lt-md-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-lt-md-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-lt-md-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-lt-md-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-lt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-lt-md-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-lt-md-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-lt-md-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-lt-md-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-lt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-lt-md-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (max-width: 1279px) {
  .flex-lt-lg-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-lt-lg-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-lt-lg-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-lt-lg-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-lt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-lt-lg-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-lt-lg-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-lt-lg-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-lt-lg-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-lt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-lt-lg-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-lt-lg-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-lt-lg-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-lt-lg-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-lt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-lt-lg-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-lt-lg-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-lt-lg-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-lt-lg-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-lt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-lt-lg-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-lt-lg-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-lt-lg-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-lt-lg-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-lt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-lt-lg-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-lt-lg-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-lt-lg-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-lt-lg-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-lt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-lt-lg-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-lt-lg-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-lt-lg-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-lt-lg-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-lt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-lt-lg-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-lt-lg-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-lt-lg-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-lt-lg-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-lt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-lt-lg-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-lt-lg-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-lt-lg-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-lt-lg-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-lt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-lt-lg-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-lt-lg-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-lt-lg-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-lt-lg-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-lt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-lt-lg-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-lt-lg-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-lt-lg-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-lt-lg-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-lt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-lt-lg-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-lt-lg-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-lt-lg-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-lt-lg-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-lt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-lt-lg-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-lt-lg-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-lt-lg-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-lt-lg-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-lt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-lt-lg-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-lt-lg-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-lt-lg-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-lt-lg-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-lt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-lt-lg-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-lt-lg-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-lt-lg-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-lt-lg-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-lt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-lt-lg-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-lt-lg-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-lt-lg-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-lt-lg-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-lt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-lt-lg-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-lt-lg-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-lt-lg-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-lt-lg-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-lt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-lt-lg-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-lt-lg-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-lt-lg-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-lt-lg-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-lt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-lt-lg-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-lt-lg-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-lt-lg-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-lt-lg-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-lt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-lt-lg-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-lt-lg-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-lt-lg-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-lt-lg-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-lt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-lt-lg-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (max-width: 1919px) {
  .flex-lt-xl-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-lt-xl-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-lt-xl-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-lt-xl-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-lt-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-lt-xl-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-lt-xl-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-lt-xl-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-lt-xl-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-lt-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-lt-xl-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-lt-xl-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-lt-xl-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-lt-xl-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-lt-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-lt-xl-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-lt-xl-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-lt-xl-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-lt-xl-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-lt-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-lt-xl-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-lt-xl-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-lt-xl-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-lt-xl-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-lt-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-lt-xl-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-lt-xl-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-lt-xl-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-lt-xl-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-lt-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-lt-xl-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-lt-xl-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-lt-xl-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-lt-xl-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-lt-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-lt-xl-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-lt-xl-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-lt-xl-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-lt-xl-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-lt-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-lt-xl-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-lt-xl-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-lt-xl-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-lt-xl-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-lt-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-lt-xl-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-lt-xl-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-lt-xl-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-lt-xl-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-lt-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-lt-xl-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-lt-xl-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-lt-xl-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-lt-xl-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-lt-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-lt-xl-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-lt-xl-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-lt-xl-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-lt-xl-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-lt-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-lt-xl-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-lt-xl-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-lt-xl-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-lt-xl-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-lt-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-lt-xl-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-lt-xl-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-lt-xl-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-lt-xl-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-lt-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-lt-xl-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-lt-xl-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-lt-xl-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-lt-xl-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-lt-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-lt-xl-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-lt-xl-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-lt-xl-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-lt-xl-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-lt-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-lt-xl-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-lt-xl-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-lt-xl-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-lt-xl-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-lt-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-lt-xl-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-lt-xl-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-lt-xl-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-lt-xl-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-lt-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-lt-xl-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-lt-xl-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-lt-xl-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-lt-xl-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-lt-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-lt-xl-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-lt-xl-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-lt-xl-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-lt-xl-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-lt-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-lt-xl-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 600px) {
  .flex-gt-xs-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-gt-xs-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-gt-xs-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-gt-xs-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-gt-xs-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-gt-xs-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-gt-xs-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-gt-xs-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-gt-xs-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-gt-xs-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-gt-xs-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-gt-xs-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-gt-xs-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-gt-xs-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-gt-xs-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-gt-xs-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-gt-xs-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-gt-xs-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-gt-xs-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-gt-xs-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-gt-xs-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-gt-xs-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-gt-xs-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-gt-xs-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-gt-xs-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-gt-xs-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-gt-xs-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-gt-xs-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-gt-xs-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-gt-xs-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-gt-xs-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-gt-xs-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-gt-xs-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-gt-xs-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-gt-xs-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-gt-xs-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-gt-xs-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-gt-xs-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-gt-xs-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-gt-xs-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-gt-xs-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-gt-xs-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-gt-xs-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-gt-xs-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-gt-xs-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-gt-xs-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-gt-xs-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-gt-xs-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-gt-xs-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-gt-xs-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-gt-xs-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-gt-xs-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-gt-xs-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-gt-xs-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-gt-xs-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-gt-xs-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-gt-xs-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-gt-xs-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-gt-xs-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-gt-xs-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-gt-xs-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-gt-xs-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-gt-xs-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-gt-xs-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-gt-xs-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-gt-xs-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-gt-xs-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-gt-xs-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-gt-xs-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-gt-xs-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-gt-xs-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-gt-xs-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-gt-xs-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-gt-xs-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-gt-xs-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-gt-xs-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-gt-xs-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-gt-xs-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-gt-xs-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-gt-xs-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-gt-xs-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 960px) {
  .flex-gt-sm-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-gt-sm-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-gt-sm-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-gt-sm-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-gt-sm-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-gt-sm-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-gt-sm-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-gt-sm-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-gt-sm-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-gt-sm-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-gt-sm-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-gt-sm-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-gt-sm-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-gt-sm-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-gt-sm-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-gt-sm-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-gt-sm-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-gt-sm-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-gt-sm-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-gt-sm-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-gt-sm-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-gt-sm-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-gt-sm-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-gt-sm-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-gt-sm-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-gt-sm-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-gt-sm-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-gt-sm-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-gt-sm-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-gt-sm-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-gt-sm-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-gt-sm-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-gt-sm-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-gt-sm-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-gt-sm-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-gt-sm-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-gt-sm-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-gt-sm-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-gt-sm-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-gt-sm-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-gt-sm-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-gt-sm-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-gt-sm-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-gt-sm-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-gt-sm-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-gt-sm-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-gt-sm-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-gt-sm-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-gt-sm-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-gt-sm-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-gt-sm-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-gt-sm-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-gt-sm-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-gt-sm-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-gt-sm-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-gt-sm-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-gt-sm-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-gt-sm-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-gt-sm-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-gt-sm-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-gt-sm-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-gt-sm-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-gt-sm-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-gt-sm-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-gt-sm-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-gt-sm-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-gt-sm-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-gt-sm-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-gt-sm-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-gt-sm-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-gt-sm-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-gt-sm-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-gt-sm-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-gt-sm-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-gt-sm-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-gt-sm-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-gt-sm-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-gt-sm-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-gt-sm-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-gt-sm-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-gt-sm-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 1280px) {
  .flex-gt-md-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-gt-md-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-gt-md-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-gt-md-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-gt-md-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-gt-md-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-gt-md-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-gt-md-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-gt-md-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-gt-md-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-gt-md-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-gt-md-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-gt-md-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-gt-md-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-gt-md-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-gt-md-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-gt-md-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-gt-md-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-gt-md-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-gt-md-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-gt-md-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-gt-md-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-gt-md-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-gt-md-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-gt-md-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-gt-md-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-gt-md-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-gt-md-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-gt-md-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-gt-md-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-gt-md-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-gt-md-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-gt-md-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-gt-md-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-gt-md-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-gt-md-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-gt-md-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-gt-md-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-gt-md-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-gt-md-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-gt-md-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-gt-md-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-gt-md-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-gt-md-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-gt-md-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-gt-md-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-gt-md-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-gt-md-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-gt-md-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-gt-md-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-gt-md-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-gt-md-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-gt-md-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-gt-md-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-gt-md-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-gt-md-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-gt-md-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-gt-md-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-gt-md-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-gt-md-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-gt-md-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-gt-md-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-gt-md-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-gt-md-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-gt-md-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-gt-md-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-gt-md-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-gt-md-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-gt-md-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-gt-md-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-gt-md-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-gt-md-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-gt-md-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-gt-md-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-gt-md-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-gt-md-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-gt-md-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-gt-md-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-gt-md-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-gt-md-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-gt-md-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 1920px) {
  .flex-gt-lg-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-gt-lg-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-gt-lg-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-gt-lg-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-gt-lg-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-gt-lg-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-gt-lg-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-gt-lg-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-gt-lg-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-gt-lg-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-gt-lg-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-gt-lg-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-gt-lg-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-gt-lg-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-gt-lg-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-gt-lg-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-gt-lg-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-gt-lg-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-gt-lg-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-gt-lg-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-gt-lg-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-gt-lg-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-gt-lg-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-gt-lg-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-gt-lg-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-gt-lg-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-gt-lg-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-gt-lg-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-gt-lg-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-gt-lg-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-gt-lg-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-gt-lg-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-gt-lg-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-gt-lg-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-gt-lg-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-gt-lg-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-gt-lg-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-gt-lg-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-gt-lg-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-gt-lg-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-gt-lg-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-gt-lg-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-gt-lg-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-gt-lg-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-gt-lg-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-gt-lg-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-gt-lg-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-gt-lg-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-gt-lg-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-gt-lg-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-gt-lg-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-gt-lg-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-gt-lg-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-gt-lg-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-gt-lg-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-gt-lg-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-gt-lg-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-gt-lg-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-gt-lg-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-gt-lg-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-gt-lg-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-gt-lg-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-gt-lg-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-gt-lg-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-gt-lg-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-gt-lg-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-gt-lg-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-gt-lg-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-gt-lg-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-gt-lg-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-gt-lg-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-gt-lg-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-gt-lg-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-gt-lg-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-gt-lg-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-gt-lg-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-gt-lg-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-gt-lg-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-gt-lg-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-gt-lg-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-gt-lg-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 767px) {
  .flex-small-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-small-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-small-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-small-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-small-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-small-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-small-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-small-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-small-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-small-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-small-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-small-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-small-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-small-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-small-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-small-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-small-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-small-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-small-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-small-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-small-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-small-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-small-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-small-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-small-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-small-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-small-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-small-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-small-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-small-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-small-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-small-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-small-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-small-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-small-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-small-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-small-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-small-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-small-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-small-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-small-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-small-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-small-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-small-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-small-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-small-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-small-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-small-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-small-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-small-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-small-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-small-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-small-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-small-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-small-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-small-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-small-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-small-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-small-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-small-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-small-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-small-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-small-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-small-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-small-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-small-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-small-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-small-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-small-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-small-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-small-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-small-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-small-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-small-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-small-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-small-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-small-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-small-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-small-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-small-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-small-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-small-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-small-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-small-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-small-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-small-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-small-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-small-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-small-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-small-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-small-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-small-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-small-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-small-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-small-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-small-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-small-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-small-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-small-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-small-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-small-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 992px) {
  .flex-medium-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-medium-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-medium-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-medium-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-medium-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-medium-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-medium-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-medium-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-medium-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-medium-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-medium-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-medium-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-medium-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-medium-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-medium-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-medium-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-medium-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-medium-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-medium-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-medium-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-medium-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-medium-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-medium-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-medium-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-medium-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-medium-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-medium-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-medium-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-medium-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-medium-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-medium-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-medium-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-medium-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-medium-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-medium-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-medium-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-medium-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-medium-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-medium-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-medium-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-medium-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-medium-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-medium-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-medium-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-medium-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-medium-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-medium-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-medium-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-medium-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-medium-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-medium-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-medium-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-medium-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-medium-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-medium-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-medium-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-medium-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-medium-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-medium-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-medium-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-medium-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-medium-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-medium-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-medium-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-medium-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-medium-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-medium-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-medium-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-medium-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-medium-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-medium-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-medium-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-medium-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-medium-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-medium-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-medium-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-medium-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-medium-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-medium-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-medium-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-medium-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-medium-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-medium-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-medium-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-medium-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-medium-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-medium-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-medium-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-medium-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-medium-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-medium-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-medium-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-medium-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-medium-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-medium-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-medium-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-medium-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-medium-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-medium-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-medium-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-medium-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 1200px) {
  .flex-large-1 {
    flex: 1 1 100%;
    max-width: 1%;
    box-sizing: border-box;
  }
  .flex-large-2 {
    flex: 1 1 100%;
    max-width: 2%;
    box-sizing: border-box;
  }
  .flex-large-3 {
    flex: 1 1 100%;
    max-width: 3%;
    box-sizing: border-box;
  }
  .flex-large-4 {
    flex: 1 1 100%;
    max-width: 4%;
    box-sizing: border-box;
  }
  .flex-large-5 {
    flex: 1 1 100%;
    max-width: 5%;
    box-sizing: border-box;
  }
  .flex-large-6 {
    flex: 1 1 100%;
    max-width: 6%;
    box-sizing: border-box;
  }
  .flex-large-7 {
    flex: 1 1 100%;
    max-width: 7%;
    box-sizing: border-box;
  }
  .flex-large-8 {
    flex: 1 1 100%;
    max-width: 8%;
    box-sizing: border-box;
  }
  .flex-large-9 {
    flex: 1 1 100%;
    max-width: 9%;
    box-sizing: border-box;
  }
  .flex-large-10 {
    flex: 1 1 100%;
    max-width: 10%;
    box-sizing: border-box;
  }
  .flex-large-11 {
    flex: 1 1 100%;
    max-width: 11%;
    box-sizing: border-box;
  }
  .flex-large-12 {
    flex: 1 1 100%;
    max-width: 12%;
    box-sizing: border-box;
  }
  .flex-large-13 {
    flex: 1 1 100%;
    max-width: 13%;
    box-sizing: border-box;
  }
  .flex-large-14 {
    flex: 1 1 100%;
    max-width: 14%;
    box-sizing: border-box;
  }
  .flex-large-15 {
    flex: 1 1 100%;
    max-width: 15%;
    box-sizing: border-box;
  }
  .flex-large-16 {
    flex: 1 1 100%;
    max-width: 16%;
    box-sizing: border-box;
  }
  .flex-large-17 {
    flex: 1 1 100%;
    max-width: 17%;
    box-sizing: border-box;
  }
  .flex-large-18 {
    flex: 1 1 100%;
    max-width: 18%;
    box-sizing: border-box;
  }
  .flex-large-19 {
    flex: 1 1 100%;
    max-width: 19%;
    box-sizing: border-box;
  }
  .flex-large-20 {
    flex: 1 1 100%;
    max-width: 20%;
    box-sizing: border-box;
  }
  .flex-large-21 {
    flex: 1 1 100%;
    max-width: 21%;
    box-sizing: border-box;
  }
  .flex-large-22 {
    flex: 1 1 100%;
    max-width: 22%;
    box-sizing: border-box;
  }
  .flex-large-23 {
    flex: 1 1 100%;
    max-width: 23%;
    box-sizing: border-box;
  }
  .flex-large-24 {
    flex: 1 1 100%;
    max-width: 24%;
    box-sizing: border-box;
  }
  .flex-large-25 {
    flex: 1 1 100%;
    max-width: 25%;
    box-sizing: border-box;
  }
  .flex-large-26 {
    flex: 1 1 100%;
    max-width: 26%;
    box-sizing: border-box;
  }
  .flex-large-27 {
    flex: 1 1 100%;
    max-width: 27%;
    box-sizing: border-box;
  }
  .flex-large-28 {
    flex: 1 1 100%;
    max-width: 28%;
    box-sizing: border-box;
  }
  .flex-large-29 {
    flex: 1 1 100%;
    max-width: 29%;
    box-sizing: border-box;
  }
  .flex-large-30 {
    flex: 1 1 100%;
    max-width: 30%;
    box-sizing: border-box;
  }
  .flex-large-31 {
    flex: 1 1 100%;
    max-width: 31%;
    box-sizing: border-box;
  }
  .flex-large-32 {
    flex: 1 1 100%;
    max-width: 32%;
    box-sizing: border-box;
  }
  .flex-large-33 {
    flex: 1 1 100%;
    max-width: 33%;
    box-sizing: border-box;
  }
  .flex-large-34 {
    flex: 1 1 100%;
    max-width: 34%;
    box-sizing: border-box;
  }
  .flex-large-35 {
    flex: 1 1 100%;
    max-width: 35%;
    box-sizing: border-box;
  }
  .flex-large-36 {
    flex: 1 1 100%;
    max-width: 36%;
    box-sizing: border-box;
  }
  .flex-large-37 {
    flex: 1 1 100%;
    max-width: 37%;
    box-sizing: border-box;
  }
  .flex-large-38 {
    flex: 1 1 100%;
    max-width: 38%;
    box-sizing: border-box;
  }
  .flex-large-39 {
    flex: 1 1 100%;
    max-width: 39%;
    box-sizing: border-box;
  }
  .flex-large-40 {
    flex: 1 1 100%;
    max-width: 40%;
    box-sizing: border-box;
  }
  .flex-large-41 {
    flex: 1 1 100%;
    max-width: 41%;
    box-sizing: border-box;
  }
  .flex-large-42 {
    flex: 1 1 100%;
    max-width: 42%;
    box-sizing: border-box;
  }
  .flex-large-43 {
    flex: 1 1 100%;
    max-width: 43%;
    box-sizing: border-box;
  }
  .flex-large-44 {
    flex: 1 1 100%;
    max-width: 44%;
    box-sizing: border-box;
  }
  .flex-large-45 {
    flex: 1 1 100%;
    max-width: 45%;
    box-sizing: border-box;
  }
  .flex-large-46 {
    flex: 1 1 100%;
    max-width: 46%;
    box-sizing: border-box;
  }
  .flex-large-47 {
    flex: 1 1 100%;
    max-width: 47%;
    box-sizing: border-box;
  }
  .flex-large-48 {
    flex: 1 1 100%;
    max-width: 48%;
    box-sizing: border-box;
  }
  .flex-large-49 {
    flex: 1 1 100%;
    max-width: 49%;
    box-sizing: border-box;
  }
  .flex-large-50 {
    flex: 1 1 100%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .flex-large-51 {
    flex: 1 1 100%;
    max-width: 51%;
    box-sizing: border-box;
  }
  .flex-large-52 {
    flex: 1 1 100%;
    max-width: 52%;
    box-sizing: border-box;
  }
  .flex-large-53 {
    flex: 1 1 100%;
    max-width: 53%;
    box-sizing: border-box;
  }
  .flex-large-54 {
    flex: 1 1 100%;
    max-width: 54%;
    box-sizing: border-box;
  }
  .flex-large-55 {
    flex: 1 1 100%;
    max-width: 55%;
    box-sizing: border-box;
  }
  .flex-large-56 {
    flex: 1 1 100%;
    max-width: 56%;
    box-sizing: border-box;
  }
  .flex-large-57 {
    flex: 1 1 100%;
    max-width: 57%;
    box-sizing: border-box;
  }
  .flex-large-58 {
    flex: 1 1 100%;
    max-width: 58%;
    box-sizing: border-box;
  }
  .flex-large-59 {
    flex: 1 1 100%;
    max-width: 59%;
    box-sizing: border-box;
  }
  .flex-large-60 {
    flex: 1 1 100%;
    max-width: 60%;
    box-sizing: border-box;
  }
  .flex-large-61 {
    flex: 1 1 100%;
    max-width: 61%;
    box-sizing: border-box;
  }
  .flex-large-62 {
    flex: 1 1 100%;
    max-width: 62%;
    box-sizing: border-box;
  }
  .flex-large-63 {
    flex: 1 1 100%;
    max-width: 63%;
    box-sizing: border-box;
  }
  .flex-large-64 {
    flex: 1 1 100%;
    max-width: 64%;
    box-sizing: border-box;
  }
  .flex-large-65 {
    flex: 1 1 100%;
    max-width: 65%;
    box-sizing: border-box;
  }
  .flex-large-66 {
    flex: 1 1 100%;
    max-width: 66%;
    box-sizing: border-box;
  }
  .flex-large-67 {
    flex: 1 1 100%;
    max-width: 67%;
    box-sizing: border-box;
  }
  .flex-large-68 {
    flex: 1 1 100%;
    max-width: 68%;
    box-sizing: border-box;
  }
  .flex-large-69 {
    flex: 1 1 100%;
    max-width: 69%;
    box-sizing: border-box;
  }
  .flex-large-70 {
    flex: 1 1 100%;
    max-width: 70%;
    box-sizing: border-box;
  }
  .flex-large-71 {
    flex: 1 1 100%;
    max-width: 71%;
    box-sizing: border-box;
  }
  .flex-large-72 {
    flex: 1 1 100%;
    max-width: 72%;
    box-sizing: border-box;
  }
  .flex-large-73 {
    flex: 1 1 100%;
    max-width: 73%;
    box-sizing: border-box;
  }
  .flex-large-74 {
    flex: 1 1 100%;
    max-width: 74%;
    box-sizing: border-box;
  }
  .flex-large-75 {
    flex: 1 1 100%;
    max-width: 75%;
    box-sizing: border-box;
  }
  .flex-large-76 {
    flex: 1 1 100%;
    max-width: 76%;
    box-sizing: border-box;
  }
  .flex-large-77 {
    flex: 1 1 100%;
    max-width: 77%;
    box-sizing: border-box;
  }
  .flex-large-78 {
    flex: 1 1 100%;
    max-width: 78%;
    box-sizing: border-box;
  }
  .flex-large-79 {
    flex: 1 1 100%;
    max-width: 79%;
    box-sizing: border-box;
  }
  .flex-large-80 {
    flex: 1 1 100%;
    max-width: 80%;
    box-sizing: border-box;
  }
  .flex-large-81 {
    flex: 1 1 100%;
    max-width: 81%;
    box-sizing: border-box;
  }
  .flex-large-82 {
    flex: 1 1 100%;
    max-width: 82%;
    box-sizing: border-box;
  }
  .flex-large-83 {
    flex: 1 1 100%;
    max-width: 83%;
    box-sizing: border-box;
  }
  .flex-large-84 {
    flex: 1 1 100%;
    max-width: 84%;
    box-sizing: border-box;
  }
  .flex-large-85 {
    flex: 1 1 100%;
    max-width: 85%;
    box-sizing: border-box;
  }
  .flex-large-86 {
    flex: 1 1 100%;
    max-width: 86%;
    box-sizing: border-box;
  }
  .flex-large-87 {
    flex: 1 1 100%;
    max-width: 87%;
    box-sizing: border-box;
  }
  .flex-large-88 {
    flex: 1 1 100%;
    max-width: 88%;
    box-sizing: border-box;
  }
  .flex-large-89 {
    flex: 1 1 100%;
    max-width: 89%;
    box-sizing: border-box;
  }
  .flex-large-90 {
    flex: 1 1 100%;
    max-width: 90%;
    box-sizing: border-box;
  }
  .flex-large-91 {
    flex: 1 1 100%;
    max-width: 91%;
    box-sizing: border-box;
  }
  .flex-large-92 {
    flex: 1 1 100%;
    max-width: 92%;
    box-sizing: border-box;
  }
  .flex-large-93 {
    flex: 1 1 100%;
    max-width: 93%;
    box-sizing: border-box;
  }
  .flex-large-94 {
    flex: 1 1 100%;
    max-width: 94%;
    box-sizing: border-box;
  }
  .flex-large-95 {
    flex: 1 1 100%;
    max-width: 95%;
    box-sizing: border-box;
  }
  .flex-large-96 {
    flex: 1 1 100%;
    max-width: 96%;
    box-sizing: border-box;
  }
  .flex-large-97 {
    flex: 1 1 100%;
    max-width: 97%;
    box-sizing: border-box;
  }
  .flex-large-98 {
    flex: 1 1 100%;
    max-width: 98%;
    box-sizing: border-box;
  }
  .flex-large-99 {
    flex: 1 1 100%;
    max-width: 99%;
    box-sizing: border-box;
  }
  .flex-large-100 {
    flex: 1 1 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .flex-large-33-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

@media (max-width: 599px) {
  .flex-xs-row {
    flex-direction: row;
  }
}
@media (max-width: 599px) {
  .flex-xs-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (max-width: 599px) {
  .flex-xs-col {
    flex-direction: column;
  }
}
@media (max-width: 599px) {
  .flex-xs-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 600px) {
  .flex-sm-row {
    flex-direction: row;
  }
}
@media (min-width: 600px) {
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 600px) {
  .flex-sm-col {
    flex-direction: column;
  }
}
@media (min-width: 600px) {
  .flex-sm-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 960px) {
  .flex-md-row {
    flex-direction: row;
  }
}
@media (min-width: 960px) {
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 960px) {
  .flex-md-col {
    flex-direction: column;
  }
}
@media (min-width: 960px) {
  .flex-md-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1280px) {
  .flex-lg-row {
    flex-direction: row;
  }
}
@media (min-width: 1280px) {
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1280px) {
  .flex-lg-col {
    flex-direction: column;
  }
}
@media (min-width: 1280px) {
  .flex-lg-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1920px) {
  .flex-xl-row {
    flex-direction: row;
  }
}
@media (min-width: 1920px) {
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1920px) {
  .flex-xl-col {
    flex-direction: column;
  }
}
@media (min-width: 1920px) {
  .flex-xl-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (max-width: 599px) {
  .flex-lt-sm-row {
    flex-direction: row;
  }
}
@media (max-width: 599px) {
  .flex-lt-sm-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (max-width: 599px) {
  .flex-lt-sm-col {
    flex-direction: column;
  }
}
@media (max-width: 599px) {
  .flex-lt-sm-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (max-width: 959px) {
  .flex-lt-md-row {
    flex-direction: row;
  }
}
@media (max-width: 959px) {
  .flex-lt-md-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (max-width: 959px) {
  .flex-lt-md-col {
    flex-direction: column;
  }
}
@media (max-width: 959px) {
  .flex-lt-md-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1279px) {
  .flex-lt-lg-row {
    flex-direction: row;
  }
}
@media (max-width: 1279px) {
  .flex-lt-lg-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (max-width: 1279px) {
  .flex-lt-lg-col {
    flex-direction: column;
  }
}
@media (max-width: 1279px) {
  .flex-lt-lg-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1919px) {
  .flex-lt-xl-row {
    flex-direction: row;
  }
}
@media (max-width: 1919px) {
  .flex-lt-xl-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (max-width: 1919px) {
  .flex-lt-xl-col {
    flex-direction: column;
  }
}
@media (max-width: 1919px) {
  .flex-lt-xl-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 600px) {
  .flex-gt-xs-row {
    flex-direction: row;
  }
}
@media (min-width: 600px) {
  .flex-gt-xs-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 600px) {
  .flex-gt-xs-col {
    flex-direction: column;
  }
}
@media (min-width: 600px) {
  .flex-gt-xs-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 960px) {
  .flex-gt-sm-row {
    flex-direction: row;
  }
}
@media (min-width: 960px) {
  .flex-gt-sm-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 960px) {
  .flex-gt-sm-col {
    flex-direction: column;
  }
}
@media (min-width: 960px) {
  .flex-gt-sm-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1280px) {
  .flex-gt-md-row {
    flex-direction: row;
  }
}
@media (min-width: 1280px) {
  .flex-gt-md-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1280px) {
  .flex-gt-md-col {
    flex-direction: column;
  }
}
@media (min-width: 1280px) {
  .flex-gt-md-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1920px) {
  .flex-gt-lg-row {
    flex-direction: row;
  }
}
@media (min-width: 1920px) {
  .flex-gt-lg-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1920px) {
  .flex-gt-lg-col {
    flex-direction: column;
  }
}
@media (min-width: 1920px) {
  .flex-gt-lg-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 767px) {
  .flex-small-row {
    flex-direction: row;
  }
}
@media (min-width: 767px) {
  .flex-small-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 767px) {
  .flex-small-col {
    flex-direction: column;
  }
}
@media (min-width: 767px) {
  .flex-small-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 992px) {
  .flex-medium-row {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .flex-medium-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 992px) {
  .flex-medium-col {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .flex-medium-col-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1200px) {
  .flex-large-row {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .flex-large-row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1200px) {
  .flex-large-col {
    flex-direction: column;
  }
}
@media (min-width: 1200px) {
  .flex-large-col-reverse {
    flex-direction: column-reverse;
  }
}
.flex-fill {
  margin: 0;
  width: 100%;
  height: 100%;
  flex: 1 1 0%;
}

.flex-wrap {
  flex-flow: wrap;
}

@media (max-width: 599px) {
  .flex-xs-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 600px) {
  .flex-sm-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 960px) {
  .flex-md-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 1280px) {
  .flex-lg-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 1920px) {
  .flex-xl-wrap {
    flex-flow: wrap;
  }
}
@media (max-width: 599px) {
  .flex-lt-sm-wrap {
    flex-flow: wrap;
  }
}
@media (max-width: 959px) {
  .flex-lt-md-wrap {
    flex-flow: wrap;
  }
}
@media (max-width: 1279px) {
  .flex-lt-lg-wrap {
    flex-flow: wrap;
  }
}
@media (max-width: 1919px) {
  .flex-lt-xl-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 600px) {
  .flex-gt-xs-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 960px) {
  .flex-gt-sm-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 1280px) {
  .flex-gt-md-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 1920px) {
  .flex-gt-lg-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 767px) {
  .flex-small-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 992px) {
  .flex-medium-wrap {
    flex-flow: wrap;
  }
}
@media (min-width: 1200px) {
  .flex-large-wrap {
    flex-flow: wrap;
  }
}
.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

@media (max-width: 599px) {
  .justify-xs-normal {
    justify-content: normal;
  }
}
@media (max-width: 599px) {
  .justify-xs-start {
    justify-content: flex-start;
  }
}
@media (max-width: 599px) {
  .justify-xs-end {
    justify-content: flex-end;
  }
}
@media (max-width: 599px) {
  .justify-xs-center {
    justify-content: center;
  }
}
@media (max-width: 599px) {
  .justify-xs-between {
    justify-content: space-between;
  }
}
@media (max-width: 599px) {
  .justify-xs-around {
    justify-content: space-around;
  }
}
@media (max-width: 599px) {
  .justify-xs-evenly {
    justify-content: space-evenly;
  }
}
@media (max-width: 599px) {
  .justify-xs-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 600px) {
  .justify-sm-normal {
    justify-content: normal;
  }
}
@media (min-width: 600px) {
  .justify-sm-start {
    justify-content: flex-start;
  }
}
@media (min-width: 600px) {
  .justify-sm-end {
    justify-content: flex-end;
  }
}
@media (min-width: 600px) {
  .justify-sm-center {
    justify-content: center;
  }
}
@media (min-width: 600px) {
  .justify-sm-between {
    justify-content: space-between;
  }
}
@media (min-width: 600px) {
  .justify-sm-around {
    justify-content: space-around;
  }
}
@media (min-width: 600px) {
  .justify-sm-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 600px) {
  .justify-sm-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 960px) {
  .justify-md-normal {
    justify-content: normal;
  }
}
@media (min-width: 960px) {
  .justify-md-start {
    justify-content: flex-start;
  }
}
@media (min-width: 960px) {
  .justify-md-end {
    justify-content: flex-end;
  }
}
@media (min-width: 960px) {
  .justify-md-center {
    justify-content: center;
  }
}
@media (min-width: 960px) {
  .justify-md-between {
    justify-content: space-between;
  }
}
@media (min-width: 960px) {
  .justify-md-around {
    justify-content: space-around;
  }
}
@media (min-width: 960px) {
  .justify-md-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 960px) {
  .justify-md-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 1280px) {
  .justify-lg-normal {
    justify-content: normal;
  }
}
@media (min-width: 1280px) {
  .justify-lg-start {
    justify-content: flex-start;
  }
}
@media (min-width: 1280px) {
  .justify-lg-end {
    justify-content: flex-end;
  }
}
@media (min-width: 1280px) {
  .justify-lg-center {
    justify-content: center;
  }
}
@media (min-width: 1280px) {
  .justify-lg-between {
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .justify-lg-around {
    justify-content: space-around;
  }
}
@media (min-width: 1280px) {
  .justify-lg-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 1280px) {
  .justify-lg-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 1920px) {
  .justify-xl-normal {
    justify-content: normal;
  }
}
@media (min-width: 1920px) {
  .justify-xl-start {
    justify-content: flex-start;
  }
}
@media (min-width: 1920px) {
  .justify-xl-end {
    justify-content: flex-end;
  }
}
@media (min-width: 1920px) {
  .justify-xl-center {
    justify-content: center;
  }
}
@media (min-width: 1920px) {
  .justify-xl-between {
    justify-content: space-between;
  }
}
@media (min-width: 1920px) {
  .justify-xl-around {
    justify-content: space-around;
  }
}
@media (min-width: 1920px) {
  .justify-xl-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 1920px) {
  .justify-xl-stretch {
    justify-content: stretch;
  }
}
@media (max-width: 599px) {
  .justify-lt-sm-normal {
    justify-content: normal;
  }
}
@media (max-width: 599px) {
  .justify-lt-sm-start {
    justify-content: flex-start;
  }
}
@media (max-width: 599px) {
  .justify-lt-sm-end {
    justify-content: flex-end;
  }
}
@media (max-width: 599px) {
  .justify-lt-sm-center {
    justify-content: center;
  }
}
@media (max-width: 599px) {
  .justify-lt-sm-between {
    justify-content: space-between;
  }
}
@media (max-width: 599px) {
  .justify-lt-sm-around {
    justify-content: space-around;
  }
}
@media (max-width: 599px) {
  .justify-lt-sm-evenly {
    justify-content: space-evenly;
  }
}
@media (max-width: 599px) {
  .justify-lt-sm-stretch {
    justify-content: stretch;
  }
}
@media (max-width: 959px) {
  .justify-lt-md-normal {
    justify-content: normal;
  }
}
@media (max-width: 959px) {
  .justify-lt-md-start {
    justify-content: flex-start;
  }
}
@media (max-width: 959px) {
  .justify-lt-md-end {
    justify-content: flex-end;
  }
}
@media (max-width: 959px) {
  .justify-lt-md-center {
    justify-content: center;
  }
}
@media (max-width: 959px) {
  .justify-lt-md-between {
    justify-content: space-between;
  }
}
@media (max-width: 959px) {
  .justify-lt-md-around {
    justify-content: space-around;
  }
}
@media (max-width: 959px) {
  .justify-lt-md-evenly {
    justify-content: space-evenly;
  }
}
@media (max-width: 959px) {
  .justify-lt-md-stretch {
    justify-content: stretch;
  }
}
@media (max-width: 1279px) {
  .justify-lt-lg-normal {
    justify-content: normal;
  }
}
@media (max-width: 1279px) {
  .justify-lt-lg-start {
    justify-content: flex-start;
  }
}
@media (max-width: 1279px) {
  .justify-lt-lg-end {
    justify-content: flex-end;
  }
}
@media (max-width: 1279px) {
  .justify-lt-lg-center {
    justify-content: center;
  }
}
@media (max-width: 1279px) {
  .justify-lt-lg-between {
    justify-content: space-between;
  }
}
@media (max-width: 1279px) {
  .justify-lt-lg-around {
    justify-content: space-around;
  }
}
@media (max-width: 1279px) {
  .justify-lt-lg-evenly {
    justify-content: space-evenly;
  }
}
@media (max-width: 1279px) {
  .justify-lt-lg-stretch {
    justify-content: stretch;
  }
}
@media (max-width: 1919px) {
  .justify-lt-xl-normal {
    justify-content: normal;
  }
}
@media (max-width: 1919px) {
  .justify-lt-xl-start {
    justify-content: flex-start;
  }
}
@media (max-width: 1919px) {
  .justify-lt-xl-end {
    justify-content: flex-end;
  }
}
@media (max-width: 1919px) {
  .justify-lt-xl-center {
    justify-content: center;
  }
}
@media (max-width: 1919px) {
  .justify-lt-xl-between {
    justify-content: space-between;
  }
}
@media (max-width: 1919px) {
  .justify-lt-xl-around {
    justify-content: space-around;
  }
}
@media (max-width: 1919px) {
  .justify-lt-xl-evenly {
    justify-content: space-evenly;
  }
}
@media (max-width: 1919px) {
  .justify-lt-xl-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 600px) {
  .justify-gt-xs-normal {
    justify-content: normal;
  }
}
@media (min-width: 600px) {
  .justify-gt-xs-start {
    justify-content: flex-start;
  }
}
@media (min-width: 600px) {
  .justify-gt-xs-end {
    justify-content: flex-end;
  }
}
@media (min-width: 600px) {
  .justify-gt-xs-center {
    justify-content: center;
  }
}
@media (min-width: 600px) {
  .justify-gt-xs-between {
    justify-content: space-between;
  }
}
@media (min-width: 600px) {
  .justify-gt-xs-around {
    justify-content: space-around;
  }
}
@media (min-width: 600px) {
  .justify-gt-xs-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 600px) {
  .justify-gt-xs-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 960px) {
  .justify-gt-sm-normal {
    justify-content: normal;
  }
}
@media (min-width: 960px) {
  .justify-gt-sm-start {
    justify-content: flex-start;
  }
}
@media (min-width: 960px) {
  .justify-gt-sm-end {
    justify-content: flex-end;
  }
}
@media (min-width: 960px) {
  .justify-gt-sm-center {
    justify-content: center;
  }
}
@media (min-width: 960px) {
  .justify-gt-sm-between {
    justify-content: space-between;
  }
}
@media (min-width: 960px) {
  .justify-gt-sm-around {
    justify-content: space-around;
  }
}
@media (min-width: 960px) {
  .justify-gt-sm-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 960px) {
  .justify-gt-sm-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 1280px) {
  .justify-gt-md-normal {
    justify-content: normal;
  }
}
@media (min-width: 1280px) {
  .justify-gt-md-start {
    justify-content: flex-start;
  }
}
@media (min-width: 1280px) {
  .justify-gt-md-end {
    justify-content: flex-end;
  }
}
@media (min-width: 1280px) {
  .justify-gt-md-center {
    justify-content: center;
  }
}
@media (min-width: 1280px) {
  .justify-gt-md-between {
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .justify-gt-md-around {
    justify-content: space-around;
  }
}
@media (min-width: 1280px) {
  .justify-gt-md-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 1280px) {
  .justify-gt-md-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 1920px) {
  .justify-gt-lg-normal {
    justify-content: normal;
  }
}
@media (min-width: 1920px) {
  .justify-gt-lg-start {
    justify-content: flex-start;
  }
}
@media (min-width: 1920px) {
  .justify-gt-lg-end {
    justify-content: flex-end;
  }
}
@media (min-width: 1920px) {
  .justify-gt-lg-center {
    justify-content: center;
  }
}
@media (min-width: 1920px) {
  .justify-gt-lg-between {
    justify-content: space-between;
  }
}
@media (min-width: 1920px) {
  .justify-gt-lg-around {
    justify-content: space-around;
  }
}
@media (min-width: 1920px) {
  .justify-gt-lg-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 1920px) {
  .justify-gt-lg-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 767px) {
  .justify-small-normal {
    justify-content: normal;
  }
}
@media (min-width: 767px) {
  .justify-small-start {
    justify-content: flex-start;
  }
}
@media (min-width: 767px) {
  .justify-small-end {
    justify-content: flex-end;
  }
}
@media (min-width: 767px) {
  .justify-small-center {
    justify-content: center;
  }
}
@media (min-width: 767px) {
  .justify-small-between {
    justify-content: space-between;
  }
}
@media (min-width: 767px) {
  .justify-small-around {
    justify-content: space-around;
  }
}
@media (min-width: 767px) {
  .justify-small-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 767px) {
  .justify-small-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 992px) {
  .justify-medium-normal {
    justify-content: normal;
  }
}
@media (min-width: 992px) {
  .justify-medium-start {
    justify-content: flex-start;
  }
}
@media (min-width: 992px) {
  .justify-medium-end {
    justify-content: flex-end;
  }
}
@media (min-width: 992px) {
  .justify-medium-center {
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .justify-medium-between {
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .justify-medium-around {
    justify-content: space-around;
  }
}
@media (min-width: 992px) {
  .justify-medium-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 992px) {
  .justify-medium-stretch {
    justify-content: stretch;
  }
}
@media (min-width: 1200px) {
  .justify-large-normal {
    justify-content: normal;
  }
}
@media (min-width: 1200px) {
  .justify-large-start {
    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  .justify-large-end {
    justify-content: flex-end;
  }
}
@media (min-width: 1200px) {
  .justify-large-center {
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .justify-large-between {
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .justify-large-around {
    justify-content: space-around;
  }
}
@media (min-width: 1200px) {
  .justify-large-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 1200px) {
  .justify-large-stretch {
    justify-content: stretch;
  }
}
.align-start {
  align-items: flex-start;
  align-content: flex-start;
  align-self: flex-start;
}

.align-end {
  align-items: flex-end;
  align-content: flex-end;
  align-self: flex-end;
}

.align-center {
  align-items: center;
  align-content: center;
  align-self: center;
}

.align-baseline {
  align-items: baseline;
  align-content: baseline;
  align-self: baseline;
}

.align-stretch {
  align-items: stretch;
  align-content: stretch;
  align-self: stretch;
}

@media (max-width: 599px) {
  .align-xs-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (max-width: 599px) {
  .align-xs-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (max-width: 599px) {
  .align-xs-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (max-width: 599px) {
  .align-xs-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (max-width: 599px) {
  .align-xs-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 600px) {
  .align-sm-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 600px) {
  .align-sm-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 600px) {
  .align-sm-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 600px) {
  .align-sm-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 600px) {
  .align-sm-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 960px) {
  .align-md-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 960px) {
  .align-md-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 960px) {
  .align-md-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 960px) {
  .align-md-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 960px) {
  .align-md-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 1280px) {
  .align-lg-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 1280px) {
  .align-lg-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 1280px) {
  .align-lg-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 1280px) {
  .align-lg-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 1280px) {
  .align-lg-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 1920px) {
  .align-xl-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 1920px) {
  .align-xl-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 1920px) {
  .align-xl-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 1920px) {
  .align-xl-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 1920px) {
  .align-xl-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (max-width: 599px) {
  .align-lt-sm-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (max-width: 599px) {
  .align-lt-sm-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (max-width: 599px) {
  .align-lt-sm-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (max-width: 599px) {
  .align-lt-sm-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (max-width: 599px) {
  .align-lt-sm-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (max-width: 959px) {
  .align-lt-md-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (max-width: 959px) {
  .align-lt-md-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (max-width: 959px) {
  .align-lt-md-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (max-width: 959px) {
  .align-lt-md-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (max-width: 959px) {
  .align-lt-md-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (max-width: 1279px) {
  .align-lt-lg-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (max-width: 1279px) {
  .align-lt-lg-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (max-width: 1279px) {
  .align-lt-lg-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (max-width: 1279px) {
  .align-lt-lg-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (max-width: 1279px) {
  .align-lt-lg-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (max-width: 1919px) {
  .align-lt-xl-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (max-width: 1919px) {
  .align-lt-xl-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (max-width: 1919px) {
  .align-lt-xl-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (max-width: 1919px) {
  .align-lt-xl-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (max-width: 1919px) {
  .align-lt-xl-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 600px) {
  .align-gt-xs-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 600px) {
  .align-gt-xs-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 600px) {
  .align-gt-xs-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 600px) {
  .align-gt-xs-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 600px) {
  .align-gt-xs-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 960px) {
  .align-gt-sm-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 960px) {
  .align-gt-sm-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 960px) {
  .align-gt-sm-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 960px) {
  .align-gt-sm-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 960px) {
  .align-gt-sm-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 1280px) {
  .align-gt-md-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 1280px) {
  .align-gt-md-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 1280px) {
  .align-gt-md-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 1280px) {
  .align-gt-md-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 1280px) {
  .align-gt-md-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 1920px) {
  .align-gt-lg-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 1920px) {
  .align-gt-lg-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 1920px) {
  .align-gt-lg-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 1920px) {
  .align-gt-lg-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 1920px) {
  .align-gt-lg-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 767px) {
  .align-small-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 767px) {
  .align-small-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 767px) {
  .align-small-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 767px) {
  .align-small-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 767px) {
  .align-small-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 992px) {
  .align-medium-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 992px) {
  .align-medium-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 992px) {
  .align-medium-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 992px) {
  .align-medium-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 992px) {
  .align-medium-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
@media (min-width: 1200px) {
  .align-large-start {
    align-items: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}
@media (min-width: 1200px) {
  .align-large-end {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
  }
}
@media (min-width: 1200px) {
  .align-large-center {
    align-items: center;
    align-content: center;
    align-self: center;
  }
}
@media (min-width: 1200px) {
  .align-large-baseline {
    align-items: baseline;
    align-content: baseline;
    align-self: baseline;
  }
}
@media (min-width: 1200px) {
  .align-large-stretch {
    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
  }
}
.align-stretch-row {
  max-height: 100%;
}

.align-stretch-col {
  max-width: 100%;
}

.align-baseline {
  align-items: baseline;
  align-content: stretch;
}

.gap-half-rem {
  gap: 0.5rem;
}

.gap-1rem {
  gap: 1rem;
}

.gap-1-5rem {
  gap: 1.5rem;
}

.gap-2-per {
  gap: 2%;
}

.gap-16px {
  gap: 16px;
}

.gap-1 {
  gap: 1px;
}

.gap-2 {
  gap: 2px;
}

.gap-3 {
  gap: 3px;
}

.gap-4 {
  gap: 4px;
}

.gap-5 {
  gap: 5px;
}

.gap-6 {
  gap: 6px;
}

.gap-7 {
  gap: 7px;
}

.gap-8 {
  gap: 8px;
}

.gap-9 {
  gap: 9px;
}

.gap-10 {
  gap: 10px;
}

.gap-11 {
  gap: 11px;
}

.gap-12 {
  gap: 12px;
}

.gap-13 {
  gap: 13px;
}

.gap-14 {
  gap: 14px;
}

.gap-15 {
  gap: 15px;
}

.gap-16 {
  gap: 16px;
}

.gap-17 {
  gap: 17px;
}

.gap-18 {
  gap: 18px;
}

.gap-19 {
  gap: 19px;
}

.gap-20 {
  gap: 20px;
}

.gap-21 {
  gap: 21px;
}

.gap-22 {
  gap: 22px;
}

.gap-23 {
  gap: 23px;
}

.gap-24 {
  gap: 24px;
}

.gap-25 {
  gap: 25px;
}

.gap-26 {
  gap: 26px;
}

.gap-27 {
  gap: 27px;
}

.gap-28 {
  gap: 28px;
}

.gap-29 {
  gap: 29px;
}

.gap-30 {
  gap: 30px;
}

.gap-31 {
  gap: 31px;
}

.gap-32 {
  gap: 32px;
}

.gap-33 {
  gap: 33px;
}

.gap-34 {
  gap: 34px;
}

.gap-35 {
  gap: 35px;
}

.gap-36 {
  gap: 36px;
}

.gap-37 {
  gap: 37px;
}

.gap-38 {
  gap: 38px;
}

.gap-39 {
  gap: 39px;
}

.gap-40 {
  gap: 40px;
}

.grid-gap-1 {
  margin: 0 -1px -1px 0 !important;
}
.grid-gap-1 > * {
  padding: 0 1px 1px 0;
}

.grid-gap-2 {
  margin: 0 -2px -2px 0 !important;
}
.grid-gap-2 > * {
  padding: 0 2px 2px 0;
}

.grid-gap-3 {
  margin: 0 -3px -3px 0 !important;
}
.grid-gap-3 > * {
  padding: 0 3px 3px 0;
}

.grid-gap-4 {
  margin: 0 -4px -4px 0 !important;
}
.grid-gap-4 > * {
  padding: 0 4px 4px 0;
}

.grid-gap-5 {
  margin: 0 -5px -5px 0 !important;
}
.grid-gap-5 > * {
  padding: 0 5px 5px 0;
}

.grid-gap-6 {
  margin: 0 -6px -6px 0 !important;
}
.grid-gap-6 > * {
  padding: 0 6px 6px 0;
}

.grid-gap-7 {
  margin: 0 -7px -7px 0 !important;
}
.grid-gap-7 > * {
  padding: 0 7px 7px 0;
}

.grid-gap-8 {
  margin: 0 -8px -8px 0 !important;
}
.grid-gap-8 > * {
  padding: 0 8px 8px 0;
}

.grid-gap-9 {
  margin: 0 -9px -9px 0 !important;
}
.grid-gap-9 > * {
  padding: 0 9px 9px 0;
}

.grid-gap-10 {
  margin: 0 -10px -10px 0 !important;
}
.grid-gap-10 > * {
  padding: 0 10px 10px 0;
}

.grid-gap-11 {
  margin: 0 -11px -11px 0 !important;
}
.grid-gap-11 > * {
  padding: 0 11px 11px 0;
}

.grid-gap-12 {
  margin: 0 -12px -12px 0 !important;
}
.grid-gap-12 > * {
  padding: 0 12px 12px 0;
}

.grid-gap-13 {
  margin: 0 -13px -13px 0 !important;
}
.grid-gap-13 > * {
  padding: 0 13px 13px 0;
}

.grid-gap-14 {
  margin: 0 -14px -14px 0 !important;
}
.grid-gap-14 > * {
  padding: 0 14px 14px 0;
}

.grid-gap-15 {
  margin: 0 -15px -15px 0 !important;
}
.grid-gap-15 > * {
  padding: 0 15px 15px 0;
}

.grid-gap-16 {
  margin: 0 -16px -16px 0 !important;
}
.grid-gap-16 > * {
  padding: 0 16px 16px 0;
}

.grid-gap-17 {
  margin: 0 -17px -17px 0 !important;
}
.grid-gap-17 > * {
  padding: 0 17px 17px 0;
}

.grid-gap-18 {
  margin: 0 -18px -18px 0 !important;
}
.grid-gap-18 > * {
  padding: 0 18px 18px 0;
}

.grid-gap-19 {
  margin: 0 -19px -19px 0 !important;
}
.grid-gap-19 > * {
  padding: 0 19px 19px 0;
}

.grid-gap-20 {
  margin: 0 -20px -20px 0 !important;
}
.grid-gap-20 > * {
  padding: 0 20px 20px 0;
}

.grid-gap-21 {
  margin: 0 -21px -21px 0 !important;
}
.grid-gap-21 > * {
  padding: 0 21px 21px 0;
}

.grid-gap-22 {
  margin: 0 -22px -22px 0 !important;
}
.grid-gap-22 > * {
  padding: 0 22px 22px 0;
}

.grid-gap-23 {
  margin: 0 -23px -23px 0 !important;
}
.grid-gap-23 > * {
  padding: 0 23px 23px 0;
}

.grid-gap-24 {
  margin: 0 -24px -24px 0 !important;
}
.grid-gap-24 > * {
  padding: 0 24px 24px 0;
}

.grid-gap-25 {
  margin: 0 -25px -25px 0 !important;
}
.grid-gap-25 > * {
  padding: 0 25px 25px 0;
}

.grid-gap-26 {
  margin: 0 -26px -26px 0 !important;
}
.grid-gap-26 > * {
  padding: 0 26px 26px 0;
}

.grid-gap-27 {
  margin: 0 -27px -27px 0 !important;
}
.grid-gap-27 > * {
  padding: 0 27px 27px 0;
}

.grid-gap-28 {
  margin: 0 -28px -28px 0 !important;
}
.grid-gap-28 > * {
  padding: 0 28px 28px 0;
}

.grid-gap-29 {
  margin: 0 -29px -29px 0 !important;
}
.grid-gap-29 > * {
  padding: 0 29px 29px 0;
}

.grid-gap-30 {
  margin: 0 -30px -30px 0 !important;
}
.grid-gap-30 > * {
  padding: 0 30px 30px 0;
}

.grid-gap-31 {
  margin: 0 -31px -31px 0 !important;
}
.grid-gap-31 > * {
  padding: 0 31px 31px 0;
}

.grid-gap-32 {
  margin: 0 -32px -32px 0 !important;
}
.grid-gap-32 > * {
  padding: 0 32px 32px 0;
}

.grid-gap-33 {
  margin: 0 -33px -33px 0 !important;
}
.grid-gap-33 > * {
  padding: 0 33px 33px 0;
}

.grid-gap-34 {
  margin: 0 -34px -34px 0 !important;
}
.grid-gap-34 > * {
  padding: 0 34px 34px 0;
}

.grid-gap-35 {
  margin: 0 -35px -35px 0 !important;
}
.grid-gap-35 > * {
  padding: 0 35px 35px 0;
}

.grid-gap-36 {
  margin: 0 -36px -36px 0 !important;
}
.grid-gap-36 > * {
  padding: 0 36px 36px 0;
}

.grid-gap-37 {
  margin: 0 -37px -37px 0 !important;
}
.grid-gap-37 > * {
  padding: 0 37px 37px 0;
}

.grid-gap-38 {
  margin: 0 -38px -38px 0 !important;
}
.grid-gap-38 > * {
  padding: 0 38px 38px 0;
}

.grid-gap-39 {
  margin: 0 -39px -39px 0 !important;
}
.grid-gap-39 > * {
  padding: 0 39px 39px 0;
}

.grid-gap-40 {
  margin: 0 -40px -40px 0 !important;
}
.grid-gap-40 > * {
  padding: 0 40px 40px 0;
}

@media (max-width: 599px) {
  .grid-gap-xs-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-xs-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-xs-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-xs-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-xs-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-xs-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-xs-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-xs-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-xs-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-xs-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-xs-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-xs-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-xs-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-xs-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-xs-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-xs-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-xs-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-xs-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-xs-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-xs-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-xs-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-xs-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-xs-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-xs-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-xs-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-xs-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-xs-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-xs-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-xs-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-xs-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-xs-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-xs-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-xs-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-xs-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-xs-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-xs-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-xs-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-xs-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-xs-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-xs-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-xs-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-xs-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-sm-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-sm-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-sm-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-sm-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-sm-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-sm-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-sm-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-sm-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-sm-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-sm-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-sm-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-sm-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-sm-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-sm-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-sm-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-sm-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-sm-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-sm-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-sm-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-sm-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-sm-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-sm-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-sm-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-sm-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-sm-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-sm-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-sm-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-sm-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-sm-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-sm-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-sm-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-sm-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-sm-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-sm-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-sm-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-sm-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-sm-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-sm-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-sm-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-sm-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-md-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-md-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-md-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-md-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-md-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-md-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-md-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-md-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-md-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-md-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-md-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-md-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-md-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-md-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-md-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-md-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-md-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-md-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-md-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-md-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-md-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-md-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-md-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-md-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-md-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-md-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-md-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-md-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-md-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-md-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-md-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-md-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-md-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-md-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-md-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-md-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-md-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-md-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-md-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-md-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-lg-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-lg-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-lg-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-lg-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-lg-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-lg-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-lg-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-lg-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-lg-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-lg-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-lg-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-lg-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-lg-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-lg-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-lg-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-lg-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-lg-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-lg-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-lg-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-lg-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-lg-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-lg-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-lg-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-lg-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-lg-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-lg-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-lg-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-lg-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-lg-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-lg-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-lg-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-lg-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-lg-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-lg-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-lg-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-lg-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-lg-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-lg-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-lg-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-lg-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-xl-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-xl-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-xl-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-xl-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-xl-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-xl-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-xl-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-xl-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-xl-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-xl-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-xl-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-xl-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-xl-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-xl-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-xl-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-xl-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-xl-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-xl-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-xl-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-xl-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-xl-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-xl-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-xl-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-xl-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-xl-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-xl-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-xl-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-xl-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-xl-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-xl-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-xl-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-xl-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-xl-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-xl-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-xl-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-xl-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-xl-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-xl-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-xl-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-xl-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-lt-sm-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-lt-sm-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-lt-sm-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-lt-sm-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-lt-sm-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-lt-sm-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-lt-sm-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-lt-sm-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-lt-sm-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-lt-sm-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-lt-sm-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-lt-sm-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-lt-sm-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-lt-sm-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-lt-sm-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-lt-sm-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-lt-sm-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-lt-sm-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-lt-sm-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-lt-sm-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-lt-sm-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-lt-sm-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-lt-sm-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-lt-sm-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-lt-sm-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-lt-sm-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-lt-sm-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-lt-sm-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-lt-sm-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-lt-sm-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-lt-sm-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-lt-sm-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-lt-sm-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-lt-sm-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-lt-sm-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-lt-sm-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-lt-sm-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-lt-sm-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-lt-sm-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-lt-sm-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-lt-md-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-lt-md-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-lt-md-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-lt-md-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-lt-md-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-lt-md-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-lt-md-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-lt-md-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-lt-md-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-lt-md-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-lt-md-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-lt-md-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-lt-md-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-lt-md-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-lt-md-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-lt-md-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-lt-md-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-lt-md-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-lt-md-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-lt-md-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-lt-md-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-lt-md-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-lt-md-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-lt-md-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-lt-md-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-lt-md-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-lt-md-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-lt-md-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-lt-md-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-lt-md-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-lt-md-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-lt-md-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-lt-md-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-lt-md-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-lt-md-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-lt-md-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-lt-md-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-lt-md-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-lt-md-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-lt-md-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-lt-lg-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-lt-lg-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-lt-lg-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-lt-lg-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-lt-lg-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-lt-lg-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-lt-lg-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-lt-lg-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-lt-lg-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-lt-lg-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-lt-lg-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-lt-lg-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-lt-lg-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-lt-lg-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-lt-lg-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-lt-lg-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-lt-lg-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-lt-lg-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-lt-lg-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-lt-lg-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-lt-lg-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-lt-lg-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-lt-lg-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-lt-lg-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-lt-lg-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-lt-lg-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-lt-lg-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-lt-lg-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-lt-lg-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-lt-lg-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-lt-lg-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-lt-lg-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-lt-lg-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-lt-lg-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-lt-lg-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-lt-lg-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-lt-lg-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-lt-lg-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-lt-lg-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-lt-lg-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-lt-xl-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-lt-xl-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-lt-xl-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-lt-xl-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-lt-xl-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-lt-xl-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-lt-xl-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-lt-xl-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-lt-xl-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-lt-xl-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-lt-xl-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-lt-xl-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-lt-xl-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-lt-xl-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-lt-xl-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-lt-xl-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-lt-xl-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-lt-xl-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-lt-xl-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-lt-xl-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-lt-xl-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-lt-xl-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-lt-xl-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-lt-xl-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-lt-xl-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-lt-xl-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-lt-xl-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-lt-xl-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-lt-xl-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-lt-xl-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-lt-xl-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-lt-xl-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-lt-xl-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-lt-xl-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-lt-xl-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-lt-xl-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-lt-xl-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-lt-xl-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-lt-xl-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-lt-xl-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-gt-xs-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-gt-xs-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-gt-xs-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-gt-xs-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-gt-xs-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-gt-xs-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-gt-xs-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-gt-xs-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-gt-xs-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-gt-xs-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-gt-xs-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-gt-xs-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-gt-xs-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-gt-xs-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-gt-xs-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-gt-xs-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-gt-xs-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-gt-xs-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-gt-xs-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-gt-xs-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-gt-xs-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-gt-xs-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-gt-xs-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-gt-xs-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-gt-xs-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-gt-xs-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-gt-xs-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-gt-xs-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-gt-xs-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-gt-xs-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-gt-xs-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-gt-xs-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-gt-xs-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-gt-xs-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-gt-xs-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-gt-xs-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-gt-xs-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-gt-xs-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-gt-xs-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-gt-xs-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-gt-sm-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-gt-sm-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-gt-sm-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-gt-sm-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-gt-sm-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-gt-sm-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-gt-sm-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-gt-sm-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-gt-sm-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-gt-sm-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-gt-sm-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-gt-sm-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-gt-sm-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-gt-sm-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-gt-sm-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-gt-sm-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-gt-sm-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-gt-sm-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-gt-sm-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-gt-sm-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-gt-sm-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-gt-sm-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-gt-sm-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-gt-sm-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-gt-sm-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-gt-sm-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-gt-sm-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-gt-sm-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-gt-sm-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-gt-sm-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-gt-sm-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-gt-sm-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-gt-sm-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-gt-sm-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-gt-sm-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-gt-sm-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-gt-sm-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-gt-sm-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-gt-sm-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-gt-sm-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-gt-md-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-gt-md-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-gt-md-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-gt-md-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-gt-md-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-gt-md-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-gt-md-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-gt-md-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-gt-md-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-gt-md-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-gt-md-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-gt-md-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-gt-md-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-gt-md-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-gt-md-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-gt-md-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-gt-md-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-gt-md-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-gt-md-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-gt-md-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-gt-md-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-gt-md-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-gt-md-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-gt-md-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-gt-md-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-gt-md-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-gt-md-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-gt-md-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-gt-md-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-gt-md-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-gt-md-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-gt-md-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-gt-md-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-gt-md-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-gt-md-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-gt-md-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-gt-md-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-gt-md-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-gt-md-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-gt-md-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-gt-lg-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-gt-lg-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-gt-lg-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-gt-lg-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-gt-lg-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-gt-lg-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-gt-lg-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-gt-lg-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-gt-lg-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-gt-lg-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-gt-lg-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-gt-lg-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-gt-lg-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-gt-lg-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-gt-lg-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-gt-lg-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-gt-lg-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-gt-lg-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-gt-lg-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-gt-lg-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-gt-lg-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-gt-lg-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-gt-lg-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-gt-lg-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-gt-lg-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-gt-lg-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-gt-lg-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-gt-lg-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-gt-lg-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-gt-lg-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-gt-lg-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-gt-lg-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-gt-lg-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-gt-lg-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-gt-lg-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-gt-lg-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-gt-lg-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-gt-lg-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-gt-lg-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-gt-lg-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-small-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-small-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-small-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-small-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-small-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-small-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-small-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-small-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-small-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-small-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-small-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-small-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-small-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-small-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-small-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-small-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-small-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-small-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-small-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-small-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-small-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-small-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-small-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-small-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-small-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-small-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-small-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-small-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-small-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-small-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-small-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-small-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-small-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-small-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-small-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-small-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-small-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-small-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-small-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-small-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-medium-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-medium-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-medium-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-medium-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-medium-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-medium-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-medium-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-medium-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-medium-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-medium-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-medium-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-medium-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-medium-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-medium-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-medium-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-medium-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-medium-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-medium-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-medium-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-medium-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-medium-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-medium-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-medium-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-medium-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-medium-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-medium-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-medium-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-medium-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-medium-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-medium-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-medium-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-medium-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-medium-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-medium-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-medium-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-medium-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-medium-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-medium-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-medium-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-medium-40 > * {
    padding: 0 40px 40px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-1 {
    margin: 0 -1px -1px 0 !important;
  }
  .grid-gap-large-1 > * {
    padding: 0 1px 1px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-2 {
    margin: 0 -2px -2px 0 !important;
  }
  .grid-gap-large-2 > * {
    padding: 0 2px 2px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-3 {
    margin: 0 -3px -3px 0 !important;
  }
  .grid-gap-large-3 > * {
    padding: 0 3px 3px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-4 {
    margin: 0 -4px -4px 0 !important;
  }
  .grid-gap-large-4 > * {
    padding: 0 4px 4px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-5 {
    margin: 0 -5px -5px 0 !important;
  }
  .grid-gap-large-5 > * {
    padding: 0 5px 5px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-6 {
    margin: 0 -6px -6px 0 !important;
  }
  .grid-gap-large-6 > * {
    padding: 0 6px 6px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-7 {
    margin: 0 -7px -7px 0 !important;
  }
  .grid-gap-large-7 > * {
    padding: 0 7px 7px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-8 {
    margin: 0 -8px -8px 0 !important;
  }
  .grid-gap-large-8 > * {
    padding: 0 8px 8px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-9 {
    margin: 0 -9px -9px 0 !important;
  }
  .grid-gap-large-9 > * {
    padding: 0 9px 9px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-10 {
    margin: 0 -10px -10px 0 !important;
  }
  .grid-gap-large-10 > * {
    padding: 0 10px 10px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-11 {
    margin: 0 -11px -11px 0 !important;
  }
  .grid-gap-large-11 > * {
    padding: 0 11px 11px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-12 {
    margin: 0 -12px -12px 0 !important;
  }
  .grid-gap-large-12 > * {
    padding: 0 12px 12px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-13 {
    margin: 0 -13px -13px 0 !important;
  }
  .grid-gap-large-13 > * {
    padding: 0 13px 13px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-14 {
    margin: 0 -14px -14px 0 !important;
  }
  .grid-gap-large-14 > * {
    padding: 0 14px 14px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-15 {
    margin: 0 -15px -15px 0 !important;
  }
  .grid-gap-large-15 > * {
    padding: 0 15px 15px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-16 {
    margin: 0 -16px -16px 0 !important;
  }
  .grid-gap-large-16 > * {
    padding: 0 16px 16px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-17 {
    margin: 0 -17px -17px 0 !important;
  }
  .grid-gap-large-17 > * {
    padding: 0 17px 17px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-18 {
    margin: 0 -18px -18px 0 !important;
  }
  .grid-gap-large-18 > * {
    padding: 0 18px 18px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-19 {
    margin: 0 -19px -19px 0 !important;
  }
  .grid-gap-large-19 > * {
    padding: 0 19px 19px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-20 {
    margin: 0 -20px -20px 0 !important;
  }
  .grid-gap-large-20 > * {
    padding: 0 20px 20px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-21 {
    margin: 0 -21px -21px 0 !important;
  }
  .grid-gap-large-21 > * {
    padding: 0 21px 21px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-22 {
    margin: 0 -22px -22px 0 !important;
  }
  .grid-gap-large-22 > * {
    padding: 0 22px 22px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-23 {
    margin: 0 -23px -23px 0 !important;
  }
  .grid-gap-large-23 > * {
    padding: 0 23px 23px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-24 {
    margin: 0 -24px -24px 0 !important;
  }
  .grid-gap-large-24 > * {
    padding: 0 24px 24px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-25 {
    margin: 0 -25px -25px 0 !important;
  }
  .grid-gap-large-25 > * {
    padding: 0 25px 25px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-26 {
    margin: 0 -26px -26px 0 !important;
  }
  .grid-gap-large-26 > * {
    padding: 0 26px 26px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-27 {
    margin: 0 -27px -27px 0 !important;
  }
  .grid-gap-large-27 > * {
    padding: 0 27px 27px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-28 {
    margin: 0 -28px -28px 0 !important;
  }
  .grid-gap-large-28 > * {
    padding: 0 28px 28px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-29 {
    margin: 0 -29px -29px 0 !important;
  }
  .grid-gap-large-29 > * {
    padding: 0 29px 29px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-30 {
    margin: 0 -30px -30px 0 !important;
  }
  .grid-gap-large-30 > * {
    padding: 0 30px 30px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-31 {
    margin: 0 -31px -31px 0 !important;
  }
  .grid-gap-large-31 > * {
    padding: 0 31px 31px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-32 {
    margin: 0 -32px -32px 0 !important;
  }
  .grid-gap-large-32 > * {
    padding: 0 32px 32px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-33 {
    margin: 0 -33px -33px 0 !important;
  }
  .grid-gap-large-33 > * {
    padding: 0 33px 33px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-34 {
    margin: 0 -34px -34px 0 !important;
  }
  .grid-gap-large-34 > * {
    padding: 0 34px 34px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-35 {
    margin: 0 -35px -35px 0 !important;
  }
  .grid-gap-large-35 > * {
    padding: 0 35px 35px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-36 {
    margin: 0 -36px -36px 0 !important;
  }
  .grid-gap-large-36 > * {
    padding: 0 36px 36px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-37 {
    margin: 0 -37px -37px 0 !important;
  }
  .grid-gap-large-37 > * {
    padding: 0 37px 37px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-38 {
    margin: 0 -38px -38px 0 !important;
  }
  .grid-gap-large-38 > * {
    padding: 0 38px 38px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-39 {
    margin: 0 -39px -39px 0 !important;
  }
  .grid-gap-large-39 > * {
    padding: 0 39px 39px 0;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-40 {
    margin: 0 -40px -40px 0 !important;
  }
  .grid-gap-large-40 > * {
    padding: 0 40px 40px 0;
  }
}
.grid-gap-1rem {
  margin: 0px -1rem -1rem 0px !important;
}
.grid-gap-1rem > * {
  padding: 0px 1rem 1rem 0px;
}

@media (max-width: 599px) {
  .grid-gap-xs-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-xs-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 600px) {
  .grid-gap-sm-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-sm-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 960px) {
  .grid-gap-md-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-md-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 1280px) {
  .grid-gap-lg-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-lg-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 1920px) {
  .grid-gap-xl-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-xl-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (max-width: 599px) {
  .grid-gap-lt-sm-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-lt-sm-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (max-width: 959px) {
  .grid-gap-lt-md-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-lt-md-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (max-width: 1279px) {
  .grid-gap-lt-lg-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-lt-lg-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (max-width: 1919px) {
  .grid-gap-lt-xl-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-lt-xl-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 600px) {
  .grid-gap-gt-xs-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-gt-xs-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 960px) {
  .grid-gap-gt-sm-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-gt-sm-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 1280px) {
  .grid-gap-gt-md-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-gt-md-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 1920px) {
  .grid-gap-gt-lg-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-gt-lg-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 767px) {
  .grid-gap-small-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-small-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 992px) {
  .grid-gap-medium-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-medium-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
@media (min-width: 1200px) {
  .grid-gap-large-1rem {
    margin: 0 -1rem -1rem 0 !important;
  }
  .grid-gap-large-1rem > * {
    padding: 0 1rem 1rem 0 !important;
  }
}
.row-gap-1 > *:not(:last-child) {
  margin-right: 1px;
  margin-bottom: 0;
}

.col-gap-1 > * {
  margin-bottom: 1px;
  margin-right: 0;
}

.row-gap-2 > *:not(:last-child) {
  margin-right: 2px;
  margin-bottom: 0;
}

.col-gap-2 > * {
  margin-bottom: 2px;
  margin-right: 0;
}

.row-gap-3 > *:not(:last-child) {
  margin-right: 3px;
  margin-bottom: 0;
}

.col-gap-3 > * {
  margin-bottom: 3px;
  margin-right: 0;
}

.row-gap-4 > *:not(:last-child) {
  margin-right: 4px;
  margin-bottom: 0;
}

.col-gap-4 > * {
  margin-bottom: 4px;
  margin-right: 0;
}

.row-gap-5 > *:not(:last-child) {
  margin-right: 5px;
  margin-bottom: 0;
}

.col-gap-5 > * {
  margin-bottom: 5px;
  margin-right: 0;
}

.row-gap-6 > *:not(:last-child) {
  margin-right: 6px;
  margin-bottom: 0;
}

.col-gap-6 > * {
  margin-bottom: 6px;
  margin-right: 0;
}

.row-gap-7 > *:not(:last-child) {
  margin-right: 7px;
  margin-bottom: 0;
}

.col-gap-7 > * {
  margin-bottom: 7px;
  margin-right: 0;
}

.row-gap-8 > *:not(:last-child) {
  margin-right: 8px;
  margin-bottom: 0;
}

.col-gap-8 > * {
  margin-bottom: 8px;
  margin-right: 0;
}

.row-gap-9 > *:not(:last-child) {
  margin-right: 9px;
  margin-bottom: 0;
}

.col-gap-9 > * {
  margin-bottom: 9px;
  margin-right: 0;
}

.row-gap-10 > *:not(:last-child) {
  margin-right: 10px;
  margin-bottom: 0;
}

.col-gap-10 > * {
  margin-bottom: 10px;
  margin-right: 0;
}

.row-gap-11 > *:not(:last-child) {
  margin-right: 11px;
  margin-bottom: 0;
}

.col-gap-11 > * {
  margin-bottom: 11px;
  margin-right: 0;
}

.row-gap-12 > *:not(:last-child) {
  margin-right: 12px;
  margin-bottom: 0;
}

.col-gap-12 > * {
  margin-bottom: 12px;
  margin-right: 0;
}

.row-gap-13 > *:not(:last-child) {
  margin-right: 13px;
  margin-bottom: 0;
}

.col-gap-13 > * {
  margin-bottom: 13px;
  margin-right: 0;
}

.row-gap-14 > *:not(:last-child) {
  margin-right: 14px;
  margin-bottom: 0;
}

.col-gap-14 > * {
  margin-bottom: 14px;
  margin-right: 0;
}

.row-gap-15 > *:not(:last-child) {
  margin-right: 15px;
  margin-bottom: 0;
}

.col-gap-15 > * {
  margin-bottom: 15px;
  margin-right: 0;
}

.row-gap-16 > *:not(:last-child) {
  margin-right: 16px;
  margin-bottom: 0;
}

.col-gap-16 > * {
  margin-bottom: 16px;
  margin-right: 0;
}

.row-gap-17 > *:not(:last-child) {
  margin-right: 17px;
  margin-bottom: 0;
}

.col-gap-17 > * {
  margin-bottom: 17px;
  margin-right: 0;
}

.row-gap-18 > *:not(:last-child) {
  margin-right: 18px;
  margin-bottom: 0;
}

.col-gap-18 > * {
  margin-bottom: 18px;
  margin-right: 0;
}

.row-gap-19 > *:not(:last-child) {
  margin-right: 19px;
  margin-bottom: 0;
}

.col-gap-19 > * {
  margin-bottom: 19px;
  margin-right: 0;
}

.row-gap-20 > *:not(:last-child) {
  margin-right: 20px;
  margin-bottom: 0;
}

.col-gap-20 > * {
  margin-bottom: 20px;
  margin-right: 0;
}

.row-gap-21 > *:not(:last-child) {
  margin-right: 21px;
  margin-bottom: 0;
}

.col-gap-21 > * {
  margin-bottom: 21px;
  margin-right: 0;
}

.row-gap-22 > *:not(:last-child) {
  margin-right: 22px;
  margin-bottom: 0;
}

.col-gap-22 > * {
  margin-bottom: 22px;
  margin-right: 0;
}

.row-gap-23 > *:not(:last-child) {
  margin-right: 23px;
  margin-bottom: 0;
}

.col-gap-23 > * {
  margin-bottom: 23px;
  margin-right: 0;
}

.row-gap-24 > *:not(:last-child) {
  margin-right: 24px;
  margin-bottom: 0;
}

.col-gap-24 > * {
  margin-bottom: 24px;
  margin-right: 0;
}

.row-gap-25 > *:not(:last-child) {
  margin-right: 25px;
  margin-bottom: 0;
}

.col-gap-25 > * {
  margin-bottom: 25px;
  margin-right: 0;
}

.row-gap-26 > *:not(:last-child) {
  margin-right: 26px;
  margin-bottom: 0;
}

.col-gap-26 > * {
  margin-bottom: 26px;
  margin-right: 0;
}

.row-gap-27 > *:not(:last-child) {
  margin-right: 27px;
  margin-bottom: 0;
}

.col-gap-27 > * {
  margin-bottom: 27px;
  margin-right: 0;
}

.row-gap-28 > *:not(:last-child) {
  margin-right: 28px;
  margin-bottom: 0;
}

.col-gap-28 > * {
  margin-bottom: 28px;
  margin-right: 0;
}

.row-gap-29 > *:not(:last-child) {
  margin-right: 29px;
  margin-bottom: 0;
}

.col-gap-29 > * {
  margin-bottom: 29px;
  margin-right: 0;
}

.row-gap-30 > *:not(:last-child) {
  margin-right: 30px;
  margin-bottom: 0;
}

.col-gap-30 > * {
  margin-bottom: 30px;
  margin-right: 0;
}

.row-gap-31 > *:not(:last-child) {
  margin-right: 31px;
  margin-bottom: 0;
}

.col-gap-31 > * {
  margin-bottom: 31px;
  margin-right: 0;
}

.row-gap-32 > *:not(:last-child) {
  margin-right: 32px;
  margin-bottom: 0;
}

.col-gap-32 > * {
  margin-bottom: 32px;
  margin-right: 0;
}

.row-gap-33 > *:not(:last-child) {
  margin-right: 33px;
  margin-bottom: 0;
}

.col-gap-33 > * {
  margin-bottom: 33px;
  margin-right: 0;
}

.row-gap-34 > *:not(:last-child) {
  margin-right: 34px;
  margin-bottom: 0;
}

.col-gap-34 > * {
  margin-bottom: 34px;
  margin-right: 0;
}

.row-gap-35 > *:not(:last-child) {
  margin-right: 35px;
  margin-bottom: 0;
}

.col-gap-35 > * {
  margin-bottom: 35px;
  margin-right: 0;
}

.row-gap-36 > *:not(:last-child) {
  margin-right: 36px;
  margin-bottom: 0;
}

.col-gap-36 > * {
  margin-bottom: 36px;
  margin-right: 0;
}

.row-gap-37 > *:not(:last-child) {
  margin-right: 37px;
  margin-bottom: 0;
}

.col-gap-37 > * {
  margin-bottom: 37px;
  margin-right: 0;
}

.row-gap-38 > *:not(:last-child) {
  margin-right: 38px;
  margin-bottom: 0;
}

.col-gap-38 > * {
  margin-bottom: 38px;
  margin-right: 0;
}

.row-gap-39 > *:not(:last-child) {
  margin-right: 39px;
  margin-bottom: 0;
}

.col-gap-39 > * {
  margin-bottom: 39px;
  margin-right: 0;
}

.row-gap-40 > *:not(:last-child) {
  margin-right: 40px;
  margin-bottom: 0;
}

.col-gap-40 > * {
  margin-bottom: 40px;
  margin-right: 0;
}

@media (max-width: 599px) {
  .row-gap-xs-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-xs-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-xs-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-xs-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-xs-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-xs-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-xs-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-xs-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-xs-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-xs-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-xs-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-xs-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-xs-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-xs-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-xs-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-xs-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-xs-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-xs-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-xs-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-xs-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-xs-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-xs-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-xs-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-xs-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-xs-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-xs-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-xs-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-xs-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-xs-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-xs-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-xs-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-xs-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-xs-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-xs-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-xs-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-xs-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-xs-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-xs-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-xs-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-xs-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-xs-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-xs-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-xs-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-xs-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-xs-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-xs-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-xs-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-xs-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-xs-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-xs-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-xs-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-xs-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-xs-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-xs-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-xs-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-xs-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-xs-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-xs-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-xs-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-xs-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-xs-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-xs-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-xs-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-xs-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-xs-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-xs-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-xs-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-xs-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-xs-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-xs-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-xs-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-xs-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-xs-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-xs-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-xs-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-xs-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-xs-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-xs-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-xs-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-xs-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 600px) {
  .row-gap-sm-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-sm-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-sm-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-sm-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-sm-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-sm-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-sm-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-sm-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-sm-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-sm-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-sm-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-sm-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-sm-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-sm-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-sm-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-sm-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-sm-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-sm-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-sm-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-sm-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-sm-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-sm-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-sm-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-sm-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-sm-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-sm-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-sm-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-sm-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-sm-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-sm-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-sm-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-sm-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-sm-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-sm-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-sm-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-sm-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-sm-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-sm-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-sm-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-sm-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-sm-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-sm-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-sm-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-sm-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-sm-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-sm-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-sm-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-sm-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-sm-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-sm-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-sm-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-sm-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-sm-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-sm-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-sm-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-sm-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-sm-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-sm-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-sm-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-sm-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-sm-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-sm-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-sm-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-sm-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-sm-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-sm-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-sm-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-sm-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-sm-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-sm-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-sm-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-sm-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-sm-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-sm-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-sm-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-sm-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-sm-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-sm-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-sm-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-sm-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 960px) {
  .row-gap-md-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-md-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-md-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-md-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-md-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-md-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-md-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-md-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-md-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-md-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-md-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-md-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-md-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-md-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-md-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-md-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-md-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-md-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-md-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-md-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-md-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-md-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-md-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-md-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-md-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-md-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-md-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-md-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-md-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-md-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-md-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-md-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-md-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-md-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-md-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-md-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-md-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-md-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-md-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-md-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-md-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-md-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-md-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-md-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-md-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-md-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-md-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-md-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-md-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-md-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-md-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-md-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-md-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-md-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-md-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-md-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-md-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-md-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-md-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-md-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-md-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-md-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-md-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-md-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-md-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-md-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-md-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-md-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-md-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-md-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-md-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-md-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-md-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-md-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-md-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-md-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-md-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-md-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-md-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-md-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .row-gap-lg-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-lg-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-lg-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-lg-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-lg-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-lg-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-lg-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-lg-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-lg-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-lg-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-lg-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-lg-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-lg-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-lg-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-lg-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-lg-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-lg-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-lg-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-lg-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-lg-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-lg-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-lg-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-lg-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-lg-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-lg-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-lg-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-lg-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-lg-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-lg-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-lg-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-lg-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-lg-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-lg-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-lg-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-lg-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-lg-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-lg-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-lg-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-lg-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-lg-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-lg-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-lg-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-lg-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-lg-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-lg-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-lg-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-lg-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-lg-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-lg-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-lg-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-lg-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-lg-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-lg-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-lg-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-lg-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-lg-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-lg-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-lg-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-lg-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-lg-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-lg-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-lg-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-lg-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-lg-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-lg-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-lg-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-lg-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-lg-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-lg-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-lg-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-lg-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-lg-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-lg-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-lg-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-lg-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-lg-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-lg-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-lg-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-lg-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-lg-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 1920px) {
  .row-gap-xl-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-xl-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-xl-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-xl-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-xl-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-xl-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-xl-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-xl-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-xl-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-xl-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-xl-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-xl-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-xl-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-xl-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-xl-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-xl-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-xl-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-xl-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-xl-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-xl-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-xl-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-xl-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-xl-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-xl-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-xl-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-xl-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-xl-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-xl-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-xl-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-xl-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-xl-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-xl-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-xl-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-xl-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-xl-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-xl-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-xl-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-xl-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-xl-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-xl-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-xl-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-xl-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-xl-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-xl-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-xl-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-xl-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-xl-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-xl-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-xl-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-xl-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-xl-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-xl-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-xl-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-xl-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-xl-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-xl-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-xl-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-xl-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-xl-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-xl-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-xl-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-xl-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-xl-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-xl-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-xl-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-xl-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-xl-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-xl-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-xl-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-xl-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-xl-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-xl-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-xl-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-xl-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-xl-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-xl-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-xl-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-xl-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-xl-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-xl-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (max-width: 599px) {
  .row-gap-lt-sm-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-lt-sm-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-lt-sm-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-lt-sm-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-lt-sm-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-lt-sm-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-lt-sm-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-lt-sm-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-lt-sm-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-lt-sm-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-lt-sm-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-lt-sm-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-lt-sm-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-lt-sm-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-lt-sm-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-lt-sm-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-lt-sm-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-lt-sm-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-lt-sm-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-lt-sm-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-lt-sm-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-lt-sm-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-lt-sm-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-lt-sm-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-lt-sm-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-lt-sm-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-lt-sm-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-lt-sm-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-lt-sm-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-lt-sm-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-lt-sm-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-lt-sm-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-lt-sm-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-lt-sm-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-lt-sm-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-lt-sm-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-lt-sm-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-lt-sm-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-lt-sm-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-lt-sm-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-lt-sm-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (max-width: 959px) {
  .row-gap-lt-md-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-lt-md-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-lt-md-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-lt-md-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-lt-md-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-lt-md-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-lt-md-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-lt-md-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-lt-md-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-lt-md-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-lt-md-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-lt-md-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-lt-md-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-lt-md-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-lt-md-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-lt-md-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-lt-md-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-lt-md-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-lt-md-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-lt-md-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-lt-md-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-lt-md-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-lt-md-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-lt-md-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-lt-md-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-lt-md-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-lt-md-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-lt-md-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-lt-md-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-lt-md-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-lt-md-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-lt-md-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-lt-md-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-lt-md-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-lt-md-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-lt-md-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-lt-md-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-lt-md-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-lt-md-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-lt-md-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-lt-md-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (max-width: 1279px) {
  .row-gap-lt-lg-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-lt-lg-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-lt-lg-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-lt-lg-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-lt-lg-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-lt-lg-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-lt-lg-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-lt-lg-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-lt-lg-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-lt-lg-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-lt-lg-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-lt-lg-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-lt-lg-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-lt-lg-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-lt-lg-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-lt-lg-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-lt-lg-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-lt-lg-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-lt-lg-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-lt-lg-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-lt-lg-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-lt-lg-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-lt-lg-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-lt-lg-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-lt-lg-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-lt-lg-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-lt-lg-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-lt-lg-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-lt-lg-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-lt-lg-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-lt-lg-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-lt-lg-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-lt-lg-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-lt-lg-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-lt-lg-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-lt-lg-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-lt-lg-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-lt-lg-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-lt-lg-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-lt-lg-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-lt-lg-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (max-width: 1919px) {
  .row-gap-lt-xl-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-lt-xl-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-lt-xl-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-lt-xl-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-lt-xl-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-lt-xl-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-lt-xl-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-lt-xl-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-lt-xl-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-lt-xl-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-lt-xl-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-lt-xl-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-lt-xl-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-lt-xl-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-lt-xl-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-lt-xl-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-lt-xl-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-lt-xl-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-lt-xl-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-lt-xl-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-lt-xl-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-lt-xl-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-lt-xl-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-lt-xl-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-lt-xl-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-lt-xl-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-lt-xl-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-lt-xl-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-lt-xl-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-lt-xl-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-lt-xl-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-lt-xl-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-lt-xl-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-lt-xl-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-lt-xl-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-lt-xl-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-lt-xl-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-lt-xl-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-lt-xl-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-lt-xl-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-lt-xl-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 600px) {
  .row-gap-gt-xs-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-gt-xs-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-gt-xs-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-gt-xs-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-gt-xs-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-gt-xs-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-gt-xs-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-gt-xs-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-gt-xs-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-gt-xs-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-gt-xs-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-gt-xs-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-gt-xs-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-gt-xs-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-gt-xs-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-gt-xs-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-gt-xs-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-gt-xs-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-gt-xs-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-gt-xs-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-gt-xs-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-gt-xs-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-gt-xs-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-gt-xs-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-gt-xs-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-gt-xs-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-gt-xs-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-gt-xs-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-gt-xs-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-gt-xs-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-gt-xs-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-gt-xs-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-gt-xs-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-gt-xs-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-gt-xs-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-gt-xs-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-gt-xs-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-gt-xs-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-gt-xs-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-gt-xs-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-gt-xs-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 960px) {
  .row-gap-gt-sm-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-gt-sm-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-gt-sm-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-gt-sm-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-gt-sm-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-gt-sm-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-gt-sm-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-gt-sm-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-gt-sm-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-gt-sm-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-gt-sm-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-gt-sm-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-gt-sm-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-gt-sm-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-gt-sm-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-gt-sm-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-gt-sm-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-gt-sm-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-gt-sm-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-gt-sm-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-gt-sm-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-gt-sm-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-gt-sm-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-gt-sm-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-gt-sm-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-gt-sm-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-gt-sm-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-gt-sm-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-gt-sm-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-gt-sm-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-gt-sm-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-gt-sm-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-gt-sm-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-gt-sm-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-gt-sm-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-gt-sm-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-gt-sm-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-gt-sm-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-gt-sm-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-gt-sm-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-gt-sm-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .row-gap-gt-md-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-gt-md-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-gt-md-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-gt-md-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-gt-md-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-gt-md-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-gt-md-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-gt-md-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-gt-md-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-gt-md-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-gt-md-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-gt-md-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-gt-md-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-gt-md-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-gt-md-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-gt-md-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-gt-md-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-gt-md-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-gt-md-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-gt-md-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-gt-md-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-gt-md-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-gt-md-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-gt-md-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-gt-md-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-gt-md-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-gt-md-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-gt-md-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-gt-md-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-gt-md-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-gt-md-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-gt-md-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-gt-md-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-gt-md-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-gt-md-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-gt-md-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-gt-md-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-gt-md-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-gt-md-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-gt-md-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-gt-md-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 1920px) {
  .row-gap-gt-lg-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-gt-lg-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-gt-lg-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-gt-lg-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-gt-lg-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-gt-lg-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-gt-lg-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-gt-lg-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-gt-lg-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-gt-lg-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-gt-lg-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-gt-lg-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-gt-lg-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-gt-lg-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-gt-lg-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-gt-lg-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-gt-lg-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-gt-lg-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-gt-lg-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-gt-lg-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-gt-lg-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-gt-lg-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-gt-lg-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-gt-lg-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-gt-lg-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-gt-lg-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-gt-lg-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-gt-lg-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-gt-lg-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-gt-lg-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-gt-lg-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-gt-lg-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-gt-lg-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-gt-lg-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-gt-lg-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-gt-lg-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-gt-lg-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-gt-lg-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-gt-lg-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-gt-lg-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-gt-lg-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 767px) {
  .row-gap-small-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-small-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-small-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-small-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-small-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-small-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-small-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-small-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-small-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-small-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-small-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-small-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-small-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-small-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-small-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-small-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-small-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-small-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-small-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-small-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-small-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-small-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-small-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-small-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-small-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-small-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-small-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-small-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-small-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-small-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-small-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-small-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-small-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-small-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-small-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-small-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-small-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-small-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-small-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-small-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-small-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-small-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-small-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-small-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-small-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-small-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-small-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-small-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-small-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-small-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-small-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-small-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-small-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-small-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-small-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-small-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-small-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-small-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-small-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-small-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-small-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-small-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-small-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-small-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-small-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-small-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-small-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-small-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-small-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-small-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-small-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-small-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-small-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-small-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-small-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-small-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-small-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-small-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-small-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-small-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .row-gap-medium-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-medium-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-medium-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-medium-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-medium-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-medium-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-medium-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-medium-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-medium-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-medium-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-medium-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-medium-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-medium-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-medium-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-medium-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-medium-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-medium-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-medium-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-medium-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-medium-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-medium-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-medium-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-medium-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-medium-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-medium-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-medium-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-medium-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-medium-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-medium-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-medium-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-medium-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-medium-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-medium-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-medium-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-medium-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-medium-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-medium-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-medium-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-medium-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-medium-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-medium-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-medium-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-medium-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-medium-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-medium-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-medium-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-medium-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-medium-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-medium-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-medium-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-medium-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-medium-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-medium-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-medium-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-medium-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-medium-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-medium-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-medium-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-medium-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-medium-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-medium-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-medium-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-medium-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-medium-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-medium-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-medium-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-medium-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-medium-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-medium-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-medium-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-medium-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-medium-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-medium-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-medium-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-medium-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-medium-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-medium-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-medium-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-medium-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-medium-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .row-gap-large-1 > *:not(:last-child) {
    margin-right: 1px;
    margin-bottom: 0;
  }
  .col-gap-large-1 > * {
    margin-bottom: 1px;
    margin-right: 0;
  }
  .row-gap-large-2 > *:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 0;
  }
  .col-gap-large-2 > * {
    margin-bottom: 2px;
    margin-right: 0;
  }
  .row-gap-large-3 > *:not(:last-child) {
    margin-right: 3px;
    margin-bottom: 0;
  }
  .col-gap-large-3 > * {
    margin-bottom: 3px;
    margin-right: 0;
  }
  .row-gap-large-4 > *:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0;
  }
  .col-gap-large-4 > * {
    margin-bottom: 4px;
    margin-right: 0;
  }
  .row-gap-large-5 > *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .col-gap-large-5 > * {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .row-gap-large-6 > *:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 0;
  }
  .col-gap-large-6 > * {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .row-gap-large-7 > *:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }
  .col-gap-large-7 > * {
    margin-bottom: 7px;
    margin-right: 0;
  }
  .row-gap-large-8 > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .col-gap-large-8 > * {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .row-gap-large-9 > *:not(:last-child) {
    margin-right: 9px;
    margin-bottom: 0;
  }
  .col-gap-large-9 > * {
    margin-bottom: 9px;
    margin-right: 0;
  }
  .row-gap-large-10 > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .col-gap-large-10 > * {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .row-gap-large-11 > *:not(:last-child) {
    margin-right: 11px;
    margin-bottom: 0;
  }
  .col-gap-large-11 > * {
    margin-bottom: 11px;
    margin-right: 0;
  }
  .row-gap-large-12 > *:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .col-gap-large-12 > * {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .row-gap-large-13 > *:not(:last-child) {
    margin-right: 13px;
    margin-bottom: 0;
  }
  .col-gap-large-13 > * {
    margin-bottom: 13px;
    margin-right: 0;
  }
  .row-gap-large-14 > *:not(:last-child) {
    margin-right: 14px;
    margin-bottom: 0;
  }
  .col-gap-large-14 > * {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .row-gap-large-15 > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .col-gap-large-15 > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .row-gap-large-16 > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .col-gap-large-16 > * {
    margin-bottom: 16px;
    margin-right: 0;
  }
  .row-gap-large-17 > *:not(:last-child) {
    margin-right: 17px;
    margin-bottom: 0;
  }
  .col-gap-large-17 > * {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .row-gap-large-18 > *:not(:last-child) {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .col-gap-large-18 > * {
    margin-bottom: 18px;
    margin-right: 0;
  }
  .row-gap-large-19 > *:not(:last-child) {
    margin-right: 19px;
    margin-bottom: 0;
  }
  .col-gap-large-19 > * {
    margin-bottom: 19px;
    margin-right: 0;
  }
  .row-gap-large-20 > *:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .col-gap-large-20 > * {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .row-gap-large-21 > *:not(:last-child) {
    margin-right: 21px;
    margin-bottom: 0;
  }
  .col-gap-large-21 > * {
    margin-bottom: 21px;
    margin-right: 0;
  }
  .row-gap-large-22 > *:not(:last-child) {
    margin-right: 22px;
    margin-bottom: 0;
  }
  .col-gap-large-22 > * {
    margin-bottom: 22px;
    margin-right: 0;
  }
  .row-gap-large-23 > *:not(:last-child) {
    margin-right: 23px;
    margin-bottom: 0;
  }
  .col-gap-large-23 > * {
    margin-bottom: 23px;
    margin-right: 0;
  }
  .row-gap-large-24 > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .col-gap-large-24 > * {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .row-gap-large-25 > *:not(:last-child) {
    margin-right: 25px;
    margin-bottom: 0;
  }
  .col-gap-large-25 > * {
    margin-bottom: 25px;
    margin-right: 0;
  }
  .row-gap-large-26 > *:not(:last-child) {
    margin-right: 26px;
    margin-bottom: 0;
  }
  .col-gap-large-26 > * {
    margin-bottom: 26px;
    margin-right: 0;
  }
  .row-gap-large-27 > *:not(:last-child) {
    margin-right: 27px;
    margin-bottom: 0;
  }
  .col-gap-large-27 > * {
    margin-bottom: 27px;
    margin-right: 0;
  }
  .row-gap-large-28 > *:not(:last-child) {
    margin-right: 28px;
    margin-bottom: 0;
  }
  .col-gap-large-28 > * {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .row-gap-large-29 > *:not(:last-child) {
    margin-right: 29px;
    margin-bottom: 0;
  }
  .col-gap-large-29 > * {
    margin-bottom: 29px;
    margin-right: 0;
  }
  .row-gap-large-30 > *:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-gap-large-30 > * {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .row-gap-large-31 > *:not(:last-child) {
    margin-right: 31px;
    margin-bottom: 0;
  }
  .col-gap-large-31 > * {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .row-gap-large-32 > *:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 0;
  }
  .col-gap-large-32 > * {
    margin-bottom: 32px;
    margin-right: 0;
  }
  .row-gap-large-33 > *:not(:last-child) {
    margin-right: 33px;
    margin-bottom: 0;
  }
  .col-gap-large-33 > * {
    margin-bottom: 33px;
    margin-right: 0;
  }
  .row-gap-large-34 > *:not(:last-child) {
    margin-right: 34px;
    margin-bottom: 0;
  }
  .col-gap-large-34 > * {
    margin-bottom: 34px;
    margin-right: 0;
  }
  .row-gap-large-35 > *:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 0;
  }
  .col-gap-large-35 > * {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .row-gap-large-36 > *:not(:last-child) {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .col-gap-large-36 > * {
    margin-bottom: 36px;
    margin-right: 0;
  }
  .row-gap-large-37 > *:not(:last-child) {
    margin-right: 37px;
    margin-bottom: 0;
  }
  .col-gap-large-37 > * {
    margin-bottom: 37px;
    margin-right: 0;
  }
  .row-gap-large-38 > *:not(:last-child) {
    margin-right: 38px;
    margin-bottom: 0;
  }
  .col-gap-large-38 > * {
    margin-bottom: 38px;
    margin-right: 0;
  }
  .row-gap-large-39 > *:not(:last-child) {
    margin-right: 39px;
    margin-bottom: 0;
  }
  .col-gap-large-39 > * {
    margin-bottom: 39px;
    margin-right: 0;
  }
  .row-gap-large-40 > *:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .col-gap-large-40 > * {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
.hide {
  display: none !important;
}

.show {
  display: block !important;
}

@media (max-width: 599px) {
  .hide-xs {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 600px) {
  .hide-sm {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 960px) {
  .hide-md {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 1280px) {
  .hide-lg {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 1920px) {
  .hide-xl {
    display: none !important;
    margin: 0 !important;
  }
}
@media (max-width: 599px) {
  .hide-lt-sm {
    display: none !important;
    margin: 0 !important;
  }
}
@media (max-width: 959px) {
  .hide-lt-md {
    display: none !important;
    margin: 0 !important;
  }
}
@media (max-width: 1279px) {
  .hide-lt-lg {
    display: none !important;
    margin: 0 !important;
  }
}
@media (max-width: 1919px) {
  .hide-lt-xl {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 600px) {
  .hide-gt-xs {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 960px) {
  .hide-gt-sm {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 1280px) {
  .hide-gt-md {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 1920px) {
  .hide-gt-lg {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 767px) {
  .hide-small {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 992px) {
  .hide-medium {
    display: none !important;
    margin: 0 !important;
  }
}
@media (min-width: 1200px) {
  .hide-large {
    display: none !important;
    margin: 0 !important;
  }
}
@media (max-width: 599px) {
  .show-xs {
    display: block !important;
  }
}
@media (min-width: 600px) {
  .show-sm {
    display: block !important;
  }
}
@media (min-width: 960px) {
  .show-md {
    display: block !important;
  }
}
@media (min-width: 1280px) {
  .show-lg {
    display: block !important;
  }
}
@media (min-width: 1920px) {
  .show-xl {
    display: block !important;
  }
}
@media (max-width: 599px) {
  .show-lt-sm {
    display: block !important;
  }
}
@media (max-width: 959px) {
  .show-lt-md {
    display: block !important;
  }
}
@media (max-width: 1279px) {
  .show-lt-lg {
    display: block !important;
  }
}
@media (max-width: 1919px) {
  .show-lt-xl {
    display: block !important;
  }
}
@media (min-width: 600px) {
  .show-gt-xs {
    display: block !important;
  }
}
@media (min-width: 960px) {
  .show-gt-sm {
    display: block !important;
  }
}
@media (min-width: 1280px) {
  .show-gt-md {
    display: block !important;
  }
}
@media (min-width: 1920px) {
  .show-gt-lg {
    display: block !important;
  }
}
@media (min-width: 767px) {
  .show-small {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .show-medium {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .show-large {
    display: block !important;
  }
}
/** COLORS & FONT FAMILIES **/
/* spacing */
.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.width-10 {
  width: 10px !important;
}

/* alignment*/
.text-center {
  text-align: center;
}

.vertical-middle {
  vertical-align: middle;
}

.uppercase {
  text-transform: uppercase;
}

.width-100 {
  width: 100%;
}

.float-right {
  float: right;
  text-align: right;
}

/* colors */
.black {
  color: #231f20 !important;
}

.green {
  color: #008000;
}

.red {
  color: #c12026;
}

/** COLORS & FONT FAMILIES **/
/** COLORS & FONT FAMILIES **/
html {
  scroll-behavior: smooth;
}

div.splash-screen {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: #194867;
  margin: 0;
  padding: 0;
}
div.splash-screen h1 {
  text-transform: uppercase;
  color: white;
  font-weight: 400;
}

main {
  margin: 0px auto;
  padding: 0 2em;
}
main:focus {
  outline: none;
}

.full-height {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100px;
}

.full-width {
  width: 100%;
}

.managed-content a {
  text-decoration: none;
  color: #3f51b5;
  padding: 2px;
}
.managed-content a:hover {
  color: #3f51b5;
  background-color: #f4c94e;
}

/*** PANELS ***/
.panel {
  margin: 0 -32px;
  padding: 32px;
  min-height: 500px;
}
.panel p {
  font-size: 1.2em;
  line-height: 1.5em;
}

.panel-end {
  margin-bottom: -16px;
  margin-top: 16px;
}

.panel-gray {
  background-color: whitesmoke;
}

.panel-header-label {
  color: #f4c94e;
  font-size: 20px;
}

.panel-header-label-alt {
  color: #194867;
  font-size: 20px;
}

.blue-background {
  background: #134e8f;
  position: relative;
  background-image: url("^assets/images/blue-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.plain-blue-background {
  background: #134e8f;
  position: relative;
  background-size: cover;
  color: white;
}

.panel-header {
  font-size: 2rem;
}

.panel-header-label + .panel-header,
.panel-header-label-alt + .panel-header {
  margin-top: 6px;
}

.highlight {
  color: #f4c94e;
}

.panel mat-card mat-list-item p {
  margin: 0;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
@media (max-width: 959px) {
  .panel mat-card mat-list-item {
    height: initial !important;
  }
  .panel mat-card mat-list-item [matlistitemline] {
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
}
@media (max-width: 959px) {
  .panel mat-card mat-list mat-list-item + mat-list-item {
    margin-top: 15px;
  }
}

.circle-image {
  display: inline-block;
  position: relative;
  width: 350px;
  height: 350px;
  overflow: hidden;
  border-radius: 50%;
  border: solid 5px #ffffff;
}

/*** END PANELS ***/
/* view encapsulation on dynamic html doesn't work in component,
so putting it in global for now
*/
.masthead h1 {
  color: #ffffff;
}

.masthead p {
  font-weight: 400;
  color: #ffffff;
}

.top-header-menu a:hover {
  color: #194867;
}

/*end view encapsulation fix */
/*** SIDENAV ***/
.mat-drawer {
  background-color: #194867;
}

.sidenav-custom {
  overflow: hidden !important;
  height: 100%;
}

mat-sidenav {
  overflow: hidden;
}

/*** END SIDENAV ***/
/*** BUTTONS ***/
.mat-mdc-raised-button {
  text-transform: uppercase;
  margin-right: 8px;
  margin-bottom: 8px;
  text-wrap: nowrap;
}

/*** SEARCH ***/
.filter-bar {
  background-color: #eeeeee;
  margin: 0px -32px 16px -32px !important;
  border-radius: 0px !important;
}

.filter-icon {
  font-weight: 700 !important;
  color: #231f20 !important;
}

.filter-header {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
}

.filter mat-icon {
  margin-right: 8px;
}

mat-panel-title {
  margin-top: 0px;
  line-height: 1.5 em;
}

mat-panel-description .searchtitle {
  font-size: 1em;
  font-weight: 400;
  margin: 0;
  color: #231f20;
  line-height: 1.5 em;
  display: block;
}

.mat-accordion.filter > .mat-expansion-panel-spacing:last-child {
  margin-bottom: 16px;
}

.global-search-form-fields {
  background-color: #ffffff;
  margin: 24px 0;
}
@media (min-width: 960px) {
  .global-search-form-fields {
    width: 900px;
  }
}

/*** END SEARCH ***/
/*** LISTS ***/
.mat-mdc-paginator {
  background: none;
}
@media (max-width: 599px) {
  .mat-mdc-paginator .mat-mdc-paginator-container {
    justify-content: flex-start;
  }
}
@media (max-width: 599px) {
  .mat-mdc-paginator .mat-mdc-paginator-range-label,
  .mat-mdc-paginator .mat-mdc-paginator-page-size-label {
    margin: 0;
  }
}

/*** END LISTS ***/
.wrap {
  overflow-wrap: break-word;
  word-break: break-word;
}

div[flexLayout] + div[flexLayout] {
  margin-top: 20px;
}

.action-buttons {
  margin-top: 20px;
}
.action-buttons mat-button + mat-button,
.action-buttons .mat-mdc-raised-button + .mat-mdc-raised-button {
  margin-left: 10px;
}

.mat-mdc-raised-button + .mat-mdc-raised-button {
  margin-left: 10px;
}

.wizard-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.wizard-buttons button + button {
  margin-left: 10px;
}

table[mat-table] {
  width: 100%;
}

.mat-mdc-snack-bar-container.snackbar-success {
  --mdc-snackbar-container-color: rgb(0, 128, 0);
  --mat-mdc-snack-bar-button-color: rgb(255, 255, 255);
  --mdc-snackbar-supporting-text-color: rgb(255, 255, 255);
}
.mat-mdc-snack-bar-container.snackbar-failure {
  --mdc-snackbar-container-color: rgb(193, 32, 38);
  --mat-mdc-snack-bar-button-color: rgb(255, 255, 255);
  --mdc-snackbar-supporting-text-color: rgb(255, 255, 255);
}

.mat-mdc-tab-label-container .mat-mdc-tab-list {
  flex-grow: unset;
}
.mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab {
  min-width: 160px;
}

.link-list mat-icon {
  color: #194867 !important;
  font-weight: bold;
}

.external-link-icon {
  transform: rotate(-45deg);
}

.link {
  color: #134e8f;
  text-decoration: underline;
}

.alert.alert-warn a {
  font-weight: 700;
}
.alert.alert-warn mat-list-item {
  height: auto !important;
  padding: 0 !important;
}
.alert.alert-warn mat-list-item mat-list-icon {
  padding-left: 0;
}
.alert.alert-warn mat-list-item p {
  margin: 5px 0;
}

/*** Partner Card ***/
.partner-card {
  min-height: 305px;
}
.partner-card:hover {
  cursor: pointer;
}
.partner-card .image-container {
  height: 200px;
  margin-bottom: 16px;
}
.partner-card .image-container img {
  max-width: 175px;
  max-height: 200px;
  display: block;
  margin: auto;
}
.partner-card mat-card-footer {
  padding: 0 8px 8px 16px;
  font-size: 12px;
  line-height: 16px;
}

/*** END Partner Card ***/
.admin-menu {
  max-width: none !important;
}
.admin-menu .mat-mdc-menu-content {
  overflow: hidden;
  padding: 8px 15px;
}
.admin-menu .mat-subheader {
  font-family: "Roboto", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.admin-menu .mat-subheader mat-icon {
  margin-right: 4px;
}
.admin-menu [mat-menu-item] {
  text-overflow: initial;
  width: 100%;
}

.wiz-contain {
  margin-top: 32px;
  width: 95%;
}
@media (max-width: 1279px) {
  .wiz-contain {
    margin-top: 16px;
  }
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

pre {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  font-family: monospace;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

/* dock */
.dock a {
  color: #194867;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}
.dock a:hover {
  color: #134e8f !important;
}
.dock .green {
  background-color: #599959;
  color: white;
}
.dock .yellow {
  background-color: #eac152;
  color: white;
}
.dock .orange {
  background-color: rgb(255, 128, 0);
  color: white;
}
.dock .red {
  background-color: #c12026;
  color: white;
}
.dock .default {
  background-color: lightgray;
  color: black;
}
.dock .count {
  display: inline-flex;
  padding: 7px 12px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 17px;
  height: 1px;
  font-size: 14px;
  font-weight: 500;
}
.dock .multiple-dock-items {
  margin: 5px 0px;
}
.dock .mat-mdc-card-header-text {
  width: 100%;
}
.dock mat-card-title {
  padding-bottom: 6px;
}

mat-card.chart-dock {
  padding: 0 !important;
}
mat-card.chart-dock mat-card-header {
  background: #194867;
  border-radius: 4px 4px 0 0;
  padding-top: 7px;
}
mat-card.chart-dock mat-card-title {
  color: white;
  padding-bottom: 6px;
  margin-bottom: 0 !important;
}
mat-card.chart-dock mat-card-title mat-icon {
  line-height: 18px;
  vertical-align: middle;
}
mat-card.chart-dock mat-card-content {
  padding: 8px 16px !important;
}
mat-card.chart-dock .chart-select {
  margin: 20px;
  margin-bottom: -20px;
}

.margin-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.margin-auto {
  margin: auto;
}

mat-chip-row {
  font-weight: 500;
}

.snapshot {
  height: 100%;
}
.snapshot .number {
  font-size: 2.5em;
}

/* end dock */
/*mat table for mobile*/
@media screen and (max-width: 960px) {
  [mat-header-row] {
    display: none;
  }
  [mat-table] {
    border: 0;
    vertical-align: middle;
  }
  [mat-table] caption {
    font-size: 1em;
  }
  [mat-table] [mat-row] {
    border-bottom: 5px solid #ddd;
    display: block;
    height: 100%;
    width: 100%;
    padding: 5px;
  }
  [mat-table] [mat-cell] {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
    margin-bottom: 4%;
  }
  [mat-table] [mat-cell]:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: normal;
    font-weight: bold;
  }
  [mat-table] [mat-cell]:last-child {
    border-bottom: 0;
    padding-right: 0;
  }
  [mat-table] [mat-cell]:first-child {
    margin-top: 4%;
    padding-left: 0;
  }
}
/* end mat table for mobile */
.breadcrumb {
  color: #194867;
  font-size: 1.4em;
  margin-bottom: 20px;
}
.breadcrumb a {
  cursor: pointer;
  font-weight: 600;
  color: #194867;
  text-decoration: none;
}
.breadcrumb a:hover {
  color: #f4c94e;
}
.breadcrumb span.home {
  font-weight: 600;
  color: #194867;
}

.breadcrumb + msep-alert > mat-card {
  margin-bottom: 24px;
}

/* mat-card */
mat-card.mdc-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  display: block;
  padding: 16px;
  border-radius: 4px;
  background: white;
  color: rgba(0, 0, 0, 0.8705882353);
  font-family: Roboto, Helvetica Neue, sans-serif;
}
mat-card.mdc-card > :first-child {
  margin-top: 0;
}
mat-card.mdc-card > :last-child:not(mat-card-footer),
mat-card.mdc-card mat-card-content > :last-child:not(mat-card-footer) {
  margin-bottom: 0;
}
mat-card.mdc-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.1411764706), 0 1px 3px rgba(0, 0, 0, 0.1215686275);
}
mat-card.mdc-card mat-card-content {
  display: block;
  margin-bottom: 16px;
  padding: 0;
  font-size: 14px;
}
mat-card.mdc-card mat-card-content > :first-child {
  margin-top: 0;
}
mat-card.mdc-card mat-card-content.flex {
  display: flex;
}
mat-card.mdc-card mat-card-title {
  font-weight: 500;
  margin-bottom: 8px;
}
mat-card.mdc-card mat-card-title:not(:first-child) {
  margin-top: -4px;
}
mat-card.mdc-card mat-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5411764706);
  margin-bottom: 8px;
}
mat-card.mdc-card mat-card-subtitle:not(:first-child) {
  margin-top: -4px;
}
mat-card.mdc-card mat-card-footer {
  display: block;
  margin: 0 -16px -16px -16px;
  line-height: 16px;
}
mat-card.mdc-card.flex {
  display: flex;
}

mat-list-item mat-icon {
  margin-right: 8px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #e0e0e0;
}

.mdc-list-item__primary-text {
  font-family: Roboto, Helvetica Neue, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
}

.mat-mdc-form-field-subscript-wrapper {
  margin-bottom: 5px;
}

.mat-mdc-form-field-error {
  margin-top: -15px;
}

mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded label.mdc-floating-label--float-above {
  font-size: 14px;
}

.chart-wrap {
  position: relative;
  height: 100%;
  width: 100%;
}

.cdk-visually-hidden.cdk-focus-trap-anchor[aria-hidden=true] {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

/** COLORS & FONT FAMILIES **/
mat-radio-group > mat-radio-button {
  display: block !important;
}
@media (min-width: 960px) {
  mat-radio-group > mat-radio-button {
    display: inline-block !important;
  }
}

mat-radio-button + mat-radio-button {
  margin-top: 6px;
}
@media (min-width: 960px) {
  mat-radio-button + mat-radio-button {
    margin-top: 0;
    margin-left: 6px;
  }
}

mat-radio-group + mat-error {
  font-size: 75%;
}

mat-checkbox + mat-hint {
  display: block;
}

.static-form-field {
  color: black;
}

form div.flex + div.flex {
  margin-top: 15px;
}

.material-label {
  font-size: 0.95em;
  color: rgba(0, 0, 0, 0.6);
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
button.spinner:before {
  content: "";
  /*
  need display != none to override material styling
  as they set display = none inside of
  .mat-focus-indicator::before, _private.scss:16
  */
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

:not(div.flex) > .form-section + .form-section {
  margin-top: 25px;
}

.form-section mat-card-header {
  margin: -16px -16px 16px !important;
  border-bottom: 1px solid #cdd6da;
  padding: 16px 16px 10px !important;
}
.form-section .mat-mdc-card-header-text {
  margin: 0 !important;
}
.form-section .mat-mdc-card-title {
  font-weight: 700;
  margin: 0 0 10px;
}

/* The white-on-white of mat-card with a table is too much,
  so we remove some mat-card styles while keeping the connected header
*/
.form-section.with-table {
  background: none;
  box-shadow: none;
  padding: 0;
}
.form-section.with-table mat-card-header {
  border-bottom: none;
  margin-bottom: 0 !important;
  align-items: flex-end;
}
.form-section.with-table mat-card-header .mat-mdc-raised-button {
  margin-bottom: 0;
}

.select-default-option {
  opacity: 0.6;
}

/* Remove arrows from number input types */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.mat-prefix-spinner {
  float: left;
  margin-right: 8px;
  line-height: 27px;
}

/** COLORS & FONT FAMILIES **/
body {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  color: #333333;
}

a {
  color: #194867;
  font-weight: 700;
  text-underline-offset: 4px;
}
a:hover {
  color: #f4c94e;
}

a.mat-mdc-raised-button {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", Helvetica, Arial, "Lucida Grande", sans-serif;
}

h1 {
  font-size: 2.5em;
  line-height: 48px;
  font-weight: 900;
  margin-bottom: 8px;
  color: #194867;
}

/*** PAGE TITLE ***/
/* Content within the page title component is being placed by 
content-projection. For styles to work in projected content, they
must be defined globally instead of within a component.
*/
.page-title h1,
.simple-page-title h1 {
  color: white;
}

/*** END PAGE TITLE ***/
h2,
.subheading-2 {
  font-size: 1.5em;
  line-height: 32px;
  font-weight: 900;
  margin-bottom: 8px;
}

h3,
.subheading-3 {
  font-size: 1.25em;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-block-start: 0px;
}

.alt-search-h1 {
  font-size: 2.5em;
  line-height: 48px;
  font-weight: 900;
  margin-top: 32px;
  margin-bottom: 8px;
  color: #ffffff;
  text-align: center;
}

.alt {
  color: #ffffff !important;
}

:root {
  --mdc-outlined-text-field-outline-color: rgb(224, 224, 224);
  --mdc-outlined-text-field-label-text-size: 14px;
  --mat-sidenav-container-background-color: #194867;
  --mat-form-field-subscript-text-size: 10.5px;
  --mat-form-field-container-text-size: 14px;
  --mat-menu-item-label-text-size: 14px;
  --mat-paginator-container-background-color: transparent;
  --mat-select-trigger-text-size: 14px;
  --mat-form-field-subscript-text-line-height: 15px;
  --mdc-text-button-label-text-tracking: 0.08px;
  --mdc-protected-button-label-text-transform: uppercase;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-typography-body1-font-size: 14px;
  --mdc-typography-caption-font-size: 75%;
  --mdc-typography-caption-line-height: 15px;
}